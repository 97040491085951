import { createSlice } from "@reduxjs/toolkit";

export const packageSlice = createSlice({
    name: "package",
    initialState: {
        jumlahPackage: 1,
        totalCostPackage: 0,
    },
    reducers: {
        setJumlahPackage: (state, action) => {
            state.jumlahPackage = action.payload;
        },
        setTotalCostPackage: (state, action) => {
            state.totalCostPackage = action.payload;
        },
        resetPackageState: (state) => {
            state.jumlahPackage = 1;
            state.totalCostPackage = 0;
        },
    },
});

export const {
    setJumlahPackage,
    setTotalCostPackage,
    resetPackageState,
} = packageSlice.actions;
export default packageSlice.reducer;
