import React from 'react'
import "./backgroundImgMontain.scss";
import { useNavigate } from "react-router-dom";


const BackgroundImgMontain = ({ image, title, link }) => {
    const navigate = useNavigate();
    const handleClick = () => {
        if (link !== "#") {
            navigate(link);
        }
    }
    return (
        <div onClick={() => handleClick({link})}>
            <div className="facility-card">
                <img src={image} alt={title} className="facility-image" />
                <div className="facility-title">{title}</div>
            </div>
        </div>
        
    )
}
export default BackgroundImgMontain