import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
// import Navigation from "../../components/Navbar/Navigation";
// import Footer from "../../components/Footer/Footer";
import "./eventDetail.css";
import {
  fetchEventDetail,
  fetchEventClassDetail,
} from "../../services/eventService";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import Banner from "../../components/Banner/Banner";
import imgLogo from "../../assets/kp-mountain-logo.png";
import gunung from "../../assets/bg-mountain-view.png";
import { BeatLoader } from "react-spinners";
import PersonCounter from "../../components/PersonCounter/PersonCounter";
import {
  setTotalCostEvent,
  setSelectedClassEvent,
  resetEventState,
} from "../../features/event/eventSlice";
import {
  setJumlahTiket,
  resetTicketState,
  setTotalCost,
} from "../../features/ticket/ticketSlice";
import { useSelector, useDispatch } from "react-redux";
import { isLoggedIn } from "../../features/login/loginSlice";
import LayoutSummary from "../LayoutSummary/LayoutSummary";

const EventDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [eventDetail, setEventDetail] = useState(null);
  const [imagesEvent, setImagesEvent] = useState([]);
  console.log("imageEvent");
  console.log(imagesEvent);
  const [classes, setClasses] = useState([]);
  // const [selectedClass, setSelectedClass] = useState(null);
  const jumlahTiket = useSelector((state) => state.ticket.jumlahTiket);
  const totalCostEvent = useSelector((state) => state.event.totalCostEvent);
  const selectedClassEvent = useSelector(
    (state) => state.event.selectedClassEvent
  );
  const [classDetail, setClassDetail] = useState(null);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  // console.log("selected class");
  // console.log(selectedClass);

  //Fetch detail data event
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchEventDetail(slug);
        setImagesEvent(response.data.event.show.image);
        setEventDetail(response.data.event);
        setClasses(response.data.event.class);
        dispatch(resetTicketState());
        dispatch(resetEventState());
        // console.log(eventDetail);
      } catch (error) {
        console.error("Failed to fetch event detail:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchDetailClass = async () => {
      try {
        const response = await fetchEventClassDetail(selectedClassEvent);
        // console.log("response detail class", response);
        setClassDetail(response.data.eventClass);
        // console.log("response class detail");
        // console.log(response.data.eventClass);
      } catch (error) {
        console.error("Failed to fetch event detail:", error);
      }
    };
    fetchDetailClass();
  }, [selectedClassEvent]);

  // Calculate total cost
  // const totalEventCost = jumlahTiket * classDetail.show.price;
  // console.log('jumlahTiket', jumlahTiket);
  // console.log('classDetail', classDetail.show.price);
  // console.log('totalEventCost', totalEventCost);

  // dispatch(setTotalCostEvent(totalEventCost));
  // console.log('total', totalCostEvent);

  useEffect(() => {
    if (classDetail) {
      const totalEv = jumlahTiket * classDetail.show.price;
      // console.log("totalEventCost", totalEv);
      dispatch(setTotalCostEvent(totalEv));
    }
  }, [classDetail, jumlahTiket, dispatch]);

  // console.log("totalCostEvent", totalCostEvent);

  const images = imagesEvent.map((item) => ({
    original: item.image_path,
    thumbnail: item.image_path,
  }));

  const handleClassChange = (event) => {
    dispatch(setSelectedClassEvent(event.target.value));
  };

  return (
    <>
      {/* <Navigation /> */}
      <LayoutSummary>
        <Banner imgLogo={imgLogo} img={gunung} />

        <div className="bg-ticket" style={{ padding: " 2rem 0" }}>
          <div className="bg-event">
            <div className="container-event">
              <div className="article-event">
                <h2>{eventDetail?.show?.title ?? <BeatLoader />}</h2>
                <div className="inline-event">
                  <ImgGallery items={images} />
                  <div className="description-event">
                    <p>{eventDetail?.show?.description ?? <BeatLoader />}</p>
                    <button style={{ color: "white" }} className="btn">
                      <a href={eventDetail?.pdf_path ?? "#"}>Informasi Event</a>
                    </button>
                  </div>
                </div>
                <div className="class-selection">
                  <h3>Pilih Category/Class</h3>
                  {classes.map((classItem) => (
                    <div className="class-select" key={classItem.slug}>
                      <label htmlFor={`class-${classItem.slug}`}>
                        <input
                          type="radio"
                          id={`class-${classItem.slug}`}
                          name="class"
                          value={classItem.slug}
                          checked={selectedClassEvent === classItem.slug}
                          onChange={handleClassChange}
                        />
                        {classItem.show.title} - Rp {classItem.show.price},- ({" "}
                        {classItem.show.description})
                      </label>
                    </div>
                  ))}
                  <div className="jumlah-ticket-event">
                    <h3>Jumlah Tiket</h3>
                    <PersonCounter
                      value={jumlahTiket}
                      onChange={setJumlahTiket}
                    />
                  </div>

                  <h2 style={{ textAlign: "center" }}>
                    Total Rp {totalCostEvent},-
                  </h2>
                  <div style={{ textAlign: "center" }}>
                    <p>*Harga belum termasuk biaya admin</p>
                    {isLoggedIn ? (
                      <button
                        className="btn btn-book-event"
                        style={{ background: "#30552f", color: "white" }}
                        disabled={
                          selectedClassEvent === null || totalCostEvent === 0
                        }
                        onClick={() => navigate(`/events/${slug}/payment`)}
                      >
                        Book Now
                      </button>
                    ) : (
                      <button
                        className="btn btn-book-event"
                        style={{ background: "#30552f", color: "white" }}
                        onClick={() => navigate("/login")}
                      >
                        Login to Book
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutSummary>
      {/* <Footer /> */}
    </>
  );
};

export default EventDetail;
