import React from "react";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import "./layoutSummary.css"; // Create this CSS file for any specific styles

const LayoutSummary = ({ children }) => {
  return (
    <>
      <div className="page-container">
        <Navigation />
        <div className="content-wrap">
          <div className="header"></div>
          <div className="bg-ticket">{children}</div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default LayoutSummary;
