import React, { useState } from "react";
import "./nightCounter.css";

const NightCounter = ({ value, onChange }) => {
  // let [count, setCount] = useState(1);

  // function incrementCount() {
  //   count = count + 1;
  //   setCount(count);
  // }

  const incrementCount = () => {
    onChange(value + 1);
  };

  // function decrementCount() {
  //   count = count - 1;
  //   setCount(count);
  // }

  const decrementCount = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <div className="counter container">
      <button
        className="btnDecrement"
        onClick={decrementCount}
        disabled={value === 1}
      >
        -
      </button>
      <span className="count">{value}</span>
      <button className="btnIncrement" onClick={incrementCount}>
        +
      </button>
    </div>
    // <section className="counter container">
    //   <button
    //     className=" btnDecreament"
    //     onClick={decrementCount}
    //     disabled={count === 1}
    //   >
    //     -
    //   </button>
    //   <span className="count"> {count}</span>
    //   <button className="btnIncrement" onClick={incrementCount}>
    //     +
    //   </button>
    // </section>
  );
};

export default NightCounter;
