import React from 'react'
import Navigation from "../../components/Navbar/Navigation";
import "./detailAttraction.css";
import imageBgTheater from "../../assets/theater.png";
import Footer from "../../components/Footer/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

function DetailAttraction() {
  return (
    <>
      <Navigation />
      <div className="header"></div>

      <div className='bg'>
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-md-10 col-lg-8'>
                      <article>
                        <div className='article__title text-center'>
                            <h1>Theater Geopark Meratus</h1>
                        </div>
                        <div className='article__body'>
                          <img className='article__img' src={imageBgTheater} alt="ImageBG" />
                          <div className='row'>
                            <div className='col-md-1 text-center'>
                              <FontAwesomeIcon icon={faLocationDot} size="2x" color='#FC2947'/>
                            </div>
                            <div className='col-md-11'>
                              <span className='text_location'>Kalsel Park Mountainview - Tahura Sultan Adam</span>
                            </div>
                          </div><br />
                          <h2>Teater Geopark Meratus: Mempersembahkan Kisah Keajaiban Alam Borneo</h2><br />
                          <p>
                            Selamat datang di Teater Geopark Meratus, sebuah panggung magis di tengah-tengah hutan yang menyajikan kisah-kisah mengagumkan tentang keajaiban alam Borneo. Di dalam teater ini, para pengunjung akan dihadapkan pada petualangan visual yang memukau, mengungkapkan rahasia alam yang belum terpecahkan dan keindahan yang tak tergambarkan.
                          </p>
                          <p>Pengalaman yang Mengagumkan:</p> <br />
                          <ul className='bullets'>
                            <li>Petualangan Multi-Dimensi: Melalui penggunaan teknologi canggih, pengunjung akan diangkut ke dalam alam Borneo yang megah dan dihadapkan pada pemandangan spektakuler dari pegunungan hingga lembah sungai yang mengalir deras.</li>
                            <li>Kisah-Kisah Bersejarah: Dengan sentuhan dramatis, Teater Geopark Meratus menghidupkan kembali kisah-kisah legenda dan kejadian-kejadian bersejarah yang membentuk lanskap geologi Borneo, memberikan wawasan yang mendalam tentang sejarah alam dan budaya kawasan ini.</li>
                            <li>Edukasi yang Menghibur: Selain menghibur, pertunjukan ini juga bertujuan untuk memberikan pemahaman yang lebih baik tentang pentingnya melestarikan lingkungan dan kekayaan alam Borneo untuk generasi mendatang.</li>
                          </ul>
                          <p>Pengalaman yang Tak Terlupakan:</p>
                          <p>Teater Geopark Meratus tidak hanya sekadar pertunjukan, tetapi juga pengalaman yang mendalam dan berkesan bagi semua pengunjung. Dengan perpaduan luar biasa antara teknologi modern dan keindahan alam Borneo yang memikat, setiap pertunjukan di Teater Geopark Meratus adalah perjalanan yang tak terlupakan ke dalam keajaiban alam yang luar biasa. Saksikanlah dan biarkan dirimu terpesona oleh pesona Borneo yang abadi.</p>
                        </div>
                      </article>
                    </div>
                </div>
            </div>
        </section>
      </div>
      <Footer />
    </>
  )
}

export default DetailAttraction