import React, { useEffect } from "react";
import "./forestPackage.css";
import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";

import { MdAirportShuttle, MdKingBed } from "react-icons/md";
import { MdBathtub, MdLocationOn } from "react-icons/md";
import { FaWifi } from "react-icons/fa";
import { BsArrowRightShort } from "react-icons/bs";
// import img from "../../assets/glamping-offer.jpeg";
import imgoffer1 from "../../assets/hutan-1.jpeg";
import imgoffer2 from "../../assets/imgoffer2.jpeg";
import imgoffer3 from "../../assets/paintball-offer.webp";

const Offers = [
  {
    id: 1,
    imgSrc: imgoffer1,
    descTitle: "Package 1",
    location: "Bukit Batu",
    price: "Rp 520.000,-",
    beds: false,
    wifi: false,
    toilet: false,
    transportation: false,
    discount: "",
  },
  {
    id: 2,
    imgSrc: imgoffer1,
    descTitle: "Package 2",
    location: "Mandiangin",
    price: "Rp 1.200.000,-",
    beds: false,
    wifi: false,
    toilet: false,
    transportation: false,
    discount: "10%",
  },
];

const ForestPackage = () => {

  const navigate = useNavigate();

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <section className="offer container section">
      <div className="secContainer">
        <div
          className="secIntro"
        >
          <h2 className="secTitle">Related Package</h2>
          <p>
            Lorem ipsum dolor sit amet ipsum dolor sit amet ipsum dolor sit
            amet.
          </p>
        </div>

        <div className="mainContent grid top">
          {Offers.map(
            ({
              id,
              imgSrc,
              descTitle,
              location,
              price,
              beds,
              wifi,
              toilet,
              transportation,
              discount,
            }) => {
              return (
                <div
                  data-aos="fade-up"
                  data-aos-duration="3000"
                  className="singleOffer"
                >
                  <div className="destImage">
                    <img src={imgSrc} alt={descTitle} />
                    <span className="discount">{discount}</span>
                  </div>
                  <div className="offerBody">
                    <div className="price flex">
                      <div className="sided">
                        <h3>{descTitle}</h3>
                        <h4>{price}</h4>
                      </div>
                      <span className="status">Test</span>
                    </div>

                    {/* Amenities---------*/}
                    <div className="amenities flex">
                      {beds ? (
                        <div className="singleAmenity flex">
                          {" "}
                          <MdKingBed className="icon" />{" "}
                          <small>2 Tempat Tidur</small>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {wifi ? (
                        <div className="singleAmenity flex">
                          {" "}
                          <FaWifi className="icon" /> <small>Wifi</small>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {toilet ? (
                        <div className="singleAmenity flex">
                          {" "}
                          <MdBathtub className="icon" />{" "}
                          <small>Kamar Mandi</small>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                      {transportation ? (
                        <div className="singleAmenity flex">
                          {" "}
                          <MdAirportShuttle className="icon" />{" "}
                          <small>Akses Kedaraan</small>{" "}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="location flex">
                      <MdLocationOn className="icon" />
                      <small>{location}</small>
                    </div>
                    {/*------------------ */}

                    <button onClick={() => navigate("/detailPackage")} className="btn flex">
                      View Details
                      <BsArrowRightShort className="icon" />
                    </button>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

export default ForestPackage;
