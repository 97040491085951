import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getRestaurant = async () => {
  try {
    const response = await axios.get(`${API_URL}getRestaurant`);
    // console.log("data restaurant");
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

export const getRestaurantDetail = async (slug) => {
    try {
        const response = await axios.get(`${API_URL}getRestaurantDetail/${slug}`);
        // console.log("data restaurant detail");
        // console.log(response);
        return response;
    } catch (error) {
        console.error("Error fetching data: ", error);
    }
}
