import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./attraction.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import Navigation from "../../components/Navbar/Navigation";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import img1 from "../../assets/paralayang_adventure.png";
import img2 from "../../assets/satwa.png";
import img3 from "../../assets/theater.png";
import img4 from "../../assets/outbound.png";
import { BsArrowRightShort } from "react-icons/bs";
import Footer from "../../components/Footer/Footer";

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

const Data = [
    {
        id: 1,
        imgSrc: img1,
        descTitle: "Paralayang Adventure",
        price: "Rp 10.0000,-",
        description: "unknown",
        // navigation: "/forest-page",
    },
    {
        id: 2,
        imgSrc: img2,
        descTitle: "Habituasi Satwa Endemik",
        price: "Rp 10.0000,-",
        description: "Relaxation",
        // navigation: "/river-page",
    },
    {
        id: 3,
        imgSrc: img3,
        descTitle: "Theater Geopark Meratus",
        price: "Rp 10.0000,-",
        description: "Relaxation",
        navigation: "/DetailAttraction/Theater-Geopark-Meratus",
    },
    {
        id: 4,
        imgSrc: img4,
        descTitle: "Mountain Outbound",
        price: "Rp 10.0000,-",
        description: "Relaxation",
        // navigation: "/river-page",
    },

    {
        id: 6,
        imgSrc: img1,
        descTitle: "Kalselpark Riverside",
        price: "Rp 10.0000,-",
        description: "Relaxation",
        // navigation: "/river-page",
    },
];

const Ticket = () => {
    const navigate = useNavigate();
    return (
    <>
        <div className="bg-image-ticket">
            <Navigation />
            <div className="header"></div>
            <section className="ticket section container">
                <div className="secContainer">
                    <div className="secIntro">
                        <div className="secTitle">Attraction</div>
                    </div>
                </div>

                <div className="secIntroSub">
                    <div className="secTitleSub">Kalsesl Park Mountain View</div>
                </div>
                <Carousel responsive={responsive}>
                    {Data.map(
                        ({ id, imgSrc, descTitle, price, description, navigation }) => {
                            return (
                                <div className="attraction">
                                    <div className="att-image">
                                        <img src={imgSrc} alt="Image Title" />

                                        <div className="overlayInfo">
                                            <h3>{descTitle}</h3>
                                            <p>{price}</p>
                                            <BsArrowRightShort className="icon" onClick={() => navigate(navigation)}/>
                                        </div>
                                    </div>

                                    <div className="destFooter">
                                        {/* <div className="number">0{id}</div> */}

                                        <div className="destText flex">
                                            <h6>{descTitle}</h6>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </Carousel>


                <div className="secIntroSub">
                    <div className="secTitleSub">Kalsel Park Wonderlake</div>
                </div>
                <Carousel responsive={responsive}>
                    {Data.map(
                        ({ id, imgSrc, descTitle, price, description, navigation }) => {
                            return (
                                <div className="attraction">
                                    <div className="att-image">
                                        <img src={imgSrc} alt="Image Title" />

                                        <div className="overlayInfo">
                                            <h3>{descTitle}</h3>
                                            <p>{price}</p>
                                            <BsArrowRightShort className="icon" />
                                        </div>
                                    </div>

                                    <div className="destFooter">
                                        {/* <div className="number">0{id}</div> */}

                                        <div className="destText flex">
                                            <h6>{descTitle}</h6>
                                        </div>
                                    </div>
                                </div>
                            );
                        }
                    )}
                </Carousel>
            </section>
        </div>
        <Footer />
    </>
        
    );
};

export default Ticket;
