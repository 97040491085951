import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import BankCheckbox from "../../components/Banks/Banks";
import { useSelector } from "react-redux";
import { fetchingBookingSummary } from "../../services/ticketService";
import { AppContext } from "../../App";
import "./eventSummaryBook.css";
import LayoutSummary from "../LayoutSummary/LayoutSummary";
import { requestPaymentEvent } from "../../services/eventService";

const EventSummaryBook = () => {
  const { slug, bookingNumber } = useParams();
  const navigate = useNavigate();
  let [summaryEvent, setSummaryEvent] = useState(null);
  const { selectedBank, setTicketQr, setSelectedBank, method } =
    useContext(AppContext);
  const totalCost = useSelector((state) => state.event.totalCostEvent);
  let bank = selectedBank;

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (totalCost === 0) {
          navigate(`/events/${slug}`);
        }
        setSelectedBank(null);
        const eventSummary = await fetchingBookingSummary(bookingNumber);
        setSummaryEvent(eventSummary);
        console.log("eventSummary", eventSummary);
      } catch (error) {
        if ((error.response && error.response.status === 401) || 403) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          // setError("Failed to fetch profile");
          window.location.href = "/login";
        }
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {/* <div className="page-container">
        <Navigation />
        <div className="content-wrap">
          <div className="bg-ticket"> */}
      <LayoutSummary>
        <h1>Payment</h1>
        <div className="bg-booking-va">
          <div className="bg-white">
            <h5>Summary</h5>
            <div className="summary-bg">
              <span>
                Category/Class:{" "}
                {summaryEvent &&
                  summaryEvent.data?.transaction?.order_data?.event.title_class}
              </span>
              <span>
                Jumlah Tiket:{" "}
                {summaryEvent && summaryEvent.data?.transaction?.jumlah_ticket}
              </span>
              <span>
                Total Harga:{" "}
                {summaryEvent && summaryEvent.data?.transaction?.total_harga}
              </span>
            </div>

            <div className="summary-bg">
              <span>
                Booker Name:{" "}
                {summaryEvent && summaryEvent.data?.transaction?.booker_name}
              </span>
              <span>
                Email:
                {summaryEvent && summaryEvent.data?.transaction?.email}
              </span>
              <span>
                Phone: {summaryEvent && summaryEvent.data?.transaction?.phone}
              </span>
            </div>
            <span className="info-va">
              Pembayaran dapat dilakukan dengan metode pembayaran berikut.
            </span>
            <div className="banks-va">
              <BankCheckbox />
            </div>

            <button
              className="btn btn-book"
              onClick={() =>
                requestPaymentEvent(
                  bookingNumber,
                  method,
                  bank,
                  navigate,
                  slug,
                  setTicketQr
                )
              }
            >
              Bayar
            </button>
            <span className="need-info">
              <a href="#">Need more information?</a>{" "}
            </span>
          </div>
        </div>
      </LayoutSummary>
      {/* </div>
        </div>
        <Footer /> */}
      {/* </div> */}
    </>
  );
};

export default EventSummaryBook;
