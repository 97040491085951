import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../../services/authService";

export const loginSlice = createSlice({
  name: "login",
  initialState: {
    isLoggedIn: false,
  },
  reducers: {
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setLogout: (state) => {
      state.isLoggedIn = false;
      AuthService.logout();
    },
  },
});

export const { setIsLoggedIn, setLogout } = loginSlice.actions;
export default loginSlice.reducer;
