import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Fetch all banners
const fetchBanners = () => {
  return axios.get(`${API_URL}banners`);
};
const bannerService = {
  fetchBanners,
};

export default bannerService;
