import React from 'react'
import './packageCounter.css'
import { useDispatch } from 'react-redux';
import { setJumlahPackage } from '../../features/package/packageSlice';
const PackageCounter = ({ value, onChange }) => {
    const dispatch = useDispatch();
    const handleInputChange = (e) => {
        const inputVal = e.target.value.replace(/^0+/, "");
        const newValue = parseInt(inputVal, 10);
        if (inputVal === "") {
            onChange(0);
            dispatch(setJumlahPackage(0));
        } else if (!isNaN(newValue) && newValue >= 1) {
            onChange(newValue);
            dispatch(setJumlahPackage(newValue));
        }
    };
  return (
      <div className="personCounter container">
          <button
              className="btnDecrement"
              onClick={() => {
                  const newValue = Math.max(1, value - 1);
                  onChange(newValue);
                  dispatch(setJumlahPackage(newValue));
              }}
              disabled={value === 1 || value === ""}
          >
              -
          </button>
          <input
              style={{ width: "5rem", border: "none", height: "4rem", textAlign: "center" }}
              className="person"
              type="number"
              value={value}
              onChange={handleInputChange}
          // min="1"
          // placeholder="1"
          />
          {/* <button className="btnIncrement" onClick={incrementPerson}>
        +
      </button> */}
          <button
              className="btnIncrement"
              onClick={() => {
                  const newValue = value + 1;
                  onChange(newValue);
                  dispatch(setJumlahPackage(newValue));
              }}
          >
              +
          </button>
      </div>
  )
}

export default PackageCounter