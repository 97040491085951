import React from "react";
import comingSoon from "../../assets/coming-soon.png";
import "./comingSoon.css";
import logo from "../../assets/kalselpark-logo-new-min.png";
import ComponentClock from "./ComponentClock";
import { Link } from "react-router-dom";

const ComingSoon = () => {
  return (
    <>
      <div className="coming-soon">
        <div className="imgComingSoon">
          <img src={comingSoon} alt="Coming Soon" />
        </div>
        <Link to="/">
          <img className="logo-cs" src={logo} alt="logo" />
        </Link>
        <div className="text-content">
          <div className="cs-front">
            <h2 style={{ fontSize: "2.5rem" }}>COMING SOON</h2>
            <p>Stay tuned for more amazing experiences</p>
          </div>
          <ComponentClock />
        </div>
      </div>
    </>
  );
};

export default ComingSoon;
