import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchEvents = async () => {
  try {
    const response = await axios.get(`${API_URL}getEvent`);
    // console.log('response events');
    // console.log(response);
    return response.data;
  } catch (error) {
    console.log("Failed to fetch events:", error);
    return [];
  }
};

export const fetchEventDetail = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getEventDetail/${slug}`);
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch event detail:", error);
    return null;
  }
};

export const fetchEventClassDetail = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getEventClassDetail/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch event detail:", error);
    return null;
  }
};

export const handleBookEvent = async (
  data,
  token,
  slug,
  selectedClassEvent,
  navigate,
  setBookingNumber
) => {
  if (!token) {
    navigate("/login");
    return;
  }
  try {
    console.log('test handlebook');
    const response = await axios.post(
      `${API_URL}book/${selectedClassEvent}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log(response);
    let bookingNumber = response.data.data.transaction.booking_number;
    setBookingNumber(bookingNumber);
    // // setBookingNumber(response.data.data.transaction.booking_number);
    // console.log('booking number from handlebook'+bookingNumber);
    // console.log(response.data);
    navigate(`/events/${slug}/payment/${bookingNumber}/`);
  } catch (error) {
    console.error("Failed to book ticket:", error);
  }
};

export const requestPaymentEvent = async (
  bookingNumber,
  method,
  bank,
  navigate,
  slug,
  setTicketQr,
  // setError
) => {
  const token = localStorage.getItem("user");
  // const token = Cookies.get("user");
  if (!token) {
    // setError("User is not authenticated. Please log in.");
    navigate("/login");
    return;
  }
  try {
    const response = await axios.post(
      `${API_URL}payment/${bookingNumber}`,
      {
        method,
        bank,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("request payment");
    console.log(response);
    setTicketQr(response.data.data.transaction);
    navigate(`/events/${slug}/payment/${bookingNumber}/pay`);
    return response.data;
  } catch (error) {
    console.error("Failed to post payment:", error);
    return null;
  }
};