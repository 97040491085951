import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const getMaps = async () => {
  try {
    const response = await axios.get(`${API_URL}getMaps`);
    // console.log("data maps");
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
export const getMapsDetail = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getMapsDetail/${slug}`);
    // console.log("data maps detail");
    // console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};
