import React, { useEffect, useState } from "react";
import "./blog.css";
import { BsArrowRightShort } from "react-icons/bs";
import img1 from "../../assets/news1.jpeg";
import img2 from "../../assets/news2.jpeg";
import { useNavigate } from "react-router-dom";
import { fetchNews } from "../../services/newsService";

import Aos from "aos";
import "aos/dist/aos.css";


const Blog = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  const [Posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchNews(2, 1)
      .then((response) => {
        setPosts(response.data.newss);
        // setTotalPages(response.data.newsRecap.totalPage); // Assuming the API returns totalPages
      })
      .catch((error) => {
        console.error("Error fetching news:", error);
      });
  }, []);

  return (
    <section className="bgBlog">
      <div className="blog container section">
        <div className="secContainer">
          <div className="secIntro">
            <h2 className="secTitle" style={{ fontWeight: "600" }}>
              Kalsel Park News
            </h2>
            <p>Berita dan event tentang Kalselpark</p>
          </div>

          <div className="mainContainer grid">
            {Posts.map(({ id_news, title, description, show, slug }) => {
              return (
                <div key={id_news} className="singlePost grid">
                  <div className="imgDiv">
                    <img src={show.image} alt={title} />
                  </div>
                  <div className="postDetails">
                    <h3>{title}</h3>
                    <p style={{textAlign: 'justify'}}>
                      {description.replace(/(<([^>]+)>)/gi, "").slice(0, 100) +
                        "..."}
                    </p>
                  </div>
                  <a
                    onClick={() => {
                      navigate(`/news/${slug}`);
                    }}
                    className="flex"
                  >
                    Baca selanjutnya...
                    <BsArrowRightShort className="icon" />
                  </a>
                </div>
              );
            })}
          </div>
          <button
            className="btn"
            style={{ color: "white", marginTop: "2rem" }}
            onClick={() => navigate("/news")}
          >
            Kunjungi Halaman Berita
          </button>
        </div>
      </div>
    </section>
  );
};

export default Blog;
