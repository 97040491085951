import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navigation";
import "./detailRestaurant.css";
import img1 from "../../assets/card_background_montain/background5.png";
import img2 from "../../assets/card_background_montain/detail_restaurant2.png";
import img3 from "../../assets/card_background_montain/background5.png";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import { getRestaurantDetail } from "../../services/restaurant";
import { useParams } from "react-router-dom";

function DetailRestaurant() {
  const { slug } = useParams();
  const [restaurant, setRestaurant] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const restaurant = await getRestaurantDetail(slug);
      setRestaurant(restaurant.data.data);
    };
    fetchData();
  }, []);
  // console.log("data state single restaurant");
  // console.log(restaurant);

  const handleDownload = () => {
    // Check if the file path exists
    if (
      restaurant &&
      restaurant.restaurant &&
      restaurant.restaurant.file_path
    ) {
      // Create a temporary anchor tag to trigger the download
      const link = document.createElement("a");
      link.href = restaurant.restaurant.file_path;
      // Set the download attribute to the filename you wish to download as
      // Extract the filename from the file_path or set a default name
      const fileName =
        restaurant.restaurant.file_path.split("/").pop() || "download";
      link.setAttribute("download", fileName); // Or set a custom file name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("File path is not available.");
    }
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      {/* <div className="bg">
        <section>
          <div className="text-center">
            <h1 className="title_page">Mandiangin Hills Cafe</h1>
          </div>
          <div className="container"></div>
        </section>
      </div> */}
      <div className="blogDetailNews">
        <div className="newsHeader">
          <h1>{restaurant && restaurant.show.title}</h1>
          {/* <p>{news && news.news.created_at}</p> */}
        </div>
        <img
          className="image-news"
          src={restaurant && restaurant.show.image[0].image_path}
          alt=""
        />

        <div className="newsContent">
          <p>
            {restaurant &&
              restaurant.show.description
                .replace(/&nbsp;/gi, " ")
                .replace(/(<([^>]+)>)/gi, "")
                .replace(/&#39;/gi, "'")}
          </p>
        </div>
        <button
          onClick={handleDownload}
          style={{ color: "white" }}
          className="btn"
        >
          Download Menu Resto
        </button>
      </div>
      <Footer />
    </>
  );
}

export default DetailRestaurant;
