import React from "react";
import "./tiketComponent.css";
import tiket from "../../assets/tiket.png";
import { useNavigate } from "react-router-dom";

const TiketComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="ticketComp">
        <img
          src={tiket}
          alt=""
          onClick={() => navigate("/ticket-attraction")}
        />
      </div>
    </>
  );
};

export default TiketComponent;
