import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./VAPage.css";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import { getPaymentVA } from "../../services/ticketService";
// import { AppContext } from "../../App";
import { useParams } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import { AppContext } from "../../App";
import qrisimg from "../../assets/qris.png";

const VAPage = () => {
  const navigate = useNavigate();
  const { slug, bookingNumber } = useParams();
  const { method } = useContext(AppContext);
  const [payment, setPayment] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const payment = await getPaymentVA(slug, bookingNumber);
      setPayment(payment.data);
      console.log("payment");
      console.log(payment.data.show);
    };
    fetchData();
  }, []);

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Payment</h1>
        {method === "va" ? (
          <div className="bg-booking-va">
            <div className="bg-white">
              <h5>Virtual Account</h5>
              <div className="summary-bg">
                <h4>{payment.get_payment?.va_number ?? <BeatLoader />}</h4>
                <span>
                  Total Harga + Admin VA: <br />
                  <b>Rp {payment.get_payment?.nominal}</b>,-
                </span>
              </div>
              <div style={{ padding: "10px" }}>
                <h5>Batas Waktu Pembayaran</h5>
                <h6>
                  {payment.show?.tata_cara?.virtual_account_info.expired_in}
                </h6>
              </div>
              <div className="tata-cara">
                {payment.show?.tata_cara?.payment_instruction.map(
                  (item, index) => (
                    <div className="tata-cara-list" key={index}>
                      <h5 className="type-bank-cara">
                        {item.channel} ({item.language})
                      </h5>
                      <ol>
                        {item.step.map((step, stepIndex) => (
                          <li key={stepIndex}>{step}</li>
                        ))}
                      </ol>
                    </div>
                  )
                )}
              </div>
              <button className="btn btn-book" onClick={() => navigate("/")}>
                Mengerti
              </button>
            </div>
          </div>
        ) : (
          <div className="bg-booking-va">
            <div className="bg-white">
              <img
                style={{ width: "50%", margin: "0 auto", padding: "10px" }}
                src={qrisimg}
                alt=""
              />
              <img src={payment.show?.qris} alt="" />
              <div style={{ padding: "10px" }}>
                <h5>Total yang harus dibayar</h5>
                <h5>
                  Rp 
                  {/* ticket Stay */}
                  {payment.show?.transaction_order?.priceArr?.total}{" "}
                  {/* Ticket entrance */}
                  {payment.show?.transaction_order?.ticket?.price}{" "}
                  {/* Ticket Event */}
                  {payment.show?.transaction_order?.event?.price}{" "}
                  {/* Package */}
                  {payment.show?.transaction_order?.packageHoliday?.price}{" "}
                  ,-
                </h5>
                <button className="btn btn-book" onClick={() => navigate("/")}>
                  Mengerti
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default VAPage;
