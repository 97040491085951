import React, { useEffect, useState } from "react";
import Navigation from "../../components/Navbar/Navigation"; // Import the Navigation component
import { useNavigate } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { BsArrowRightShort } from "react-icons/bs";
import "./events.css";
import tiket from "../../assets/tiket.png";
import contact from "../../assets/contact-us.png";
import Footer from "../../components/Footer/Footer";
import Contact from "../../components/ContactComponent/ContactComponent";
import TiketComponent from "../../components/TiketComponent/TiketComponent";
import { fetchEvents } from "../../services/eventService";
import LayoutSummary from "../LayoutSummary/LayoutSummary";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 6,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const Events = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const events = await fetchEvents();
      console.log("events");
      console.log(events);
      setEvents(events.data.events);
    };
    fetchData();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {/* <Navigation /> */}
      {/* <div className="header"></div> */}
      <LayoutSummary>
        <div className="bg-events">
          <section className="events section container">
            <div className="secContainer">
              <div className="secIntro">
                <div className="secTitle">Events</div>
              </div>
            </div>
            <TiketComponent />
            <Contact />
            <Carousel responsive={responsive}>
              {events.map((event) => {
                return (
                  <div key={event.id_event} className="events-item">
                    <div className="att-image">
                      <img src={event.image[0].image_path} alt="Image Title" />

                      <div className="overlayInfo">
                        <h3>{event.title}</h3>
                        {/* <p>{date}</p> */}
                        <BsArrowRightShort
                          className="icon"
                          onClick={() => navigate(`/events/${event.slug}`)}
                        />
                      </div>
                    </div>

                    <div className="destFooter">
                      <div className="destText">
                        <h6>{event.title}</h6>
                        {/* <p>{date}</p> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </section>
        </div>
      </LayoutSummary>

      {/* <Footer /> */}
    </>
  );
};

export default Events;
