import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchMerchandise = async (limit, page) => {
  try {
    const response = await axios.get(
      `${API_URL}getMerchandise?limit=${limit}&page=${page}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch merchandise:", error);
    return []; // return an empty array if fetching fails
  }
};

export const merchDetail = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getMerchandiseDetail/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch news detail:", error);
    return null;
  }
};