import { createSlice } from "@reduxjs/toolkit";

export const ticketSlice = createSlice({
  name: "ticket",
  initialState: {
    jumlahTiket: 1,
    selectedDate: new Date().toISOString(),
    totalCost: 0,
  },
  reducers: {
    setJumlahTiket: (state, action) => {
      state.jumlahTiket = action.payload;
    },
    setSelectedDate: (state, action) => {
      // Ensure the payload is serialized before storing
      const date =
        action.payload instanceof Date
          ? action.payload.toISOString()
          : action.payload;
      state.selectedDate = date;
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload;
    },
    resetTicketState: (state) => {
      state.jumlahTiket = 1;
      state.selectedDate = new Date().toISOString();
      state.totalCost = 0;
    },
  },
});

export const {
  setJumlahTiket,
  setSelectedDate,
  setTotalCost,
  resetTicketState,
} = ticketSlice.actions;
export default ticketSlice.reducer;
