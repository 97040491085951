import React, { useState, useEffect } from "react"; // Import useContext from react
import { useParams, useNavigate } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import "./ticketDetail.css";
import PersonCounter from "../../components/PersonCounter/PersonCounter";
import DatePicker from "../../components/Date/Date";
import { fetchDetailTicket } from "../../services/ticketService";
// import { AppContext } from "../../App";
import { setIsLoggedIn } from "../../features/login/loginSlice";
import {
  setJumlahTiket,
  setSelectedDate,
  setTotalCost,
} from "../../features/ticket/ticketSlice";
import { useSelector, useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

const TicketDetail = ({ attraction }) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [ticketDetail, setTicketDetail] = useState(null);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const { jumlahTiket } = useSelector((state) => state.ticket);
  const selectedDate = new Date(
    useSelector((state) => state.ticket.selectedDate)
  );
  const dispatch = useDispatch();

  const handleLogin = () => {
    dispatch(setIsLoggedIn(true));
  };
  const [modal, setModal] = useState(false);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const toggleModal = () => {
    setModal(!modal);
  };

  //get data from API for ticket detail
  useEffect(() => {
    const fetchData = async () => {
      const detail = await fetchDetailTicket(slug);
      setTicketDetail(detail.data.ticket);
    };
    fetchData();
  }, [slug]);
  useEffect(() => {
    if (ticketDetail) {
      dispatch(setTotalCost(ticketDetail.price * jumlahTiket));
    }
  }, [ticketDetail, jumlahTiket, dispatch]);

  const handleDateChange = (selectedDate) => {
    setSelectedDate(selectedDate.toISOString());
  };

  if (!ticketDetail) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh", // This makes the div take the full viewport height
        }}
      >
        <BeatLoader />
      </div>
    );
  }
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>{ticketDetail.ticket_name} Ticket</h1>
        {modal && (
          // <h2>You need to login to book ticket</h2>
          <div className="modals1">
            <div className="modal-content">
              <p>Perlu login sebelum melakukan pembelian tiket</p>
              <button
                className="btn btn-modal"
                onClick={() => navigate("/login")}
              >
                Login
              </button>
              <span className="close" onClick={toggleModal}>
                Mengerti
              </span>
            </div>
          </div>
        )}
        <div className="bg-booking">
          <div className="bg-white">
            <h4>Berapa Tiket?</h4>
            <PersonCounter value={jumlahTiket} onChange={setJumlahTiket} />
            <h6>Harga per-tiket: Rp {ticketDetail.price}</h6>
            <h5>Tanggal Tiket</h5>
            <DatePicker onDateChange={setSelectedDate} />

            {/* { isLoggedIn ? ( <button className="btn btn-book">Book</button> : <button className="btn btn-book">Book</button> )} */}
            {isLoggedIn ? (
              <button
                className="btn btn-book"
                onClick={() => navigate(`/ticket-attraction/${slug}/payment`)}
                disabled={jumlahTiket === 0}
              >
                Book
              </button>
            ) : (
              <button className="btn btn-book" onClick={toggleModal}>
                Book
              </button>
            )}
            <span className="need-info">
              <a href="#">Butuh bantuan?</a>{" "}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TicketDetail;
