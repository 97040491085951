import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchTickets = async () => {
  try {
    const response = await axios.get(`${API_URL}getTicket`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tickets:", error);
    return []; // return an empty array if fetching fails
  }
};

export const fetchDetailTicket = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getTicketDetail/${slug}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch ticket detail:", error);
    return null; // return null if fetching fails
  }
};

// export const bookTicket = async (data) => {]

export const handleBookTicket = async (
  data,
  token,
  slug,
  navigate,
  setBookingNumber
) => {
  if (!token) {
    navigate("/login");
    return;
  }

  try {
    const response = await axios.post(`${API_URL}book/${slug}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log(response);
    let bookingNumber = response.data.data.transaction.booking_number;
    setBookingNumber(bookingNumber);
    // // setBookingNumber(response.data.data.transaction.booking_number);
    // console.log('booking number from handlebook'+bookingNumber);
    // console.log(response.data);
    navigate(`/ticket-attraction/${slug}/payment/${bookingNumber}/`);
  } catch (error) {
    console.error("Failed to book ticket:", error);
  }
};

export const fetchingBookingSummary = async (bookingNumber) => {
  const token = localStorage.getItem("user");
  try {
    const response = await axios.get(`${API_URL}bookSummary/${bookingNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("fetching booking summary");
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch booking summary:", error);
    return null;
  }
};

export const requestPayment = async (
  bookingNumber,
  method,
  bank,
  navigate,
  slug,
  setTicketQr,
  // setError
) => {
  const token = localStorage.getItem("user");
  // const token = Cookies.get("user");
  if (!token) {
    // setError("User is not authenticated. Please log in.");
    navigate("/login");
    return;
  }
  try {
    const response = await axios.post(
      `${API_URL}payment/${bookingNumber}`,
      {
        method,
        bank,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log("request payment");
    console.log(response);
    setTicketQr(response.data.data.transaction);
    navigate(`/ticket-attraction/${slug}/payment/${bookingNumber}/pay`);
    return response.data;
  } catch (error) {
    console.error("Failed to post payment:", error);
    return null;
  }
};

// function to get all ticket that has been booked by user
export const fetchTicketTransaction = async (type, payment, page, limit) => {
  const token = localStorage.getItem("user");
  try {
    const response = await axios.get(
      `${API_URL}getTransactionList?type=${type}&payment=${payment}&page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log('response page limit');
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch my ticket:", error);
    return null;
  }
};

export const fetchTicketDetailTransaction = async (
  bookingNumber,
  setTicketQrDetail
) => {
  const token = localStorage.getItem("user");
  try {
    const response = await axios.get(
      `${API_URL}getTransactionDetail/${bookingNumber}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    setTicketQrDetail(response.data.data.transaction);
    return response.data.transaction;
  } catch (error) {
    console.error("Failed to fetch ticket detail transaction:", error);
    return null;
  }
};

export const fetchStayAccomodation = async () => {
  try {
    const response = await axios.get(`${API_URL}getStay`);
    // console.log("fetch stay");
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch stay accomodation:", error);
    return [];
  }
};

export const fetchStayAccomodationDetail = async (slug) => {
  try {
    const response = await axios.get(`${API_URL}getStayDetail/${slug}`);
    // console.log('response.data.stays ===>');

    // console.log("response.data.stays");
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch ticket detail:", error);
    return null; // return null if fetching fails
  }
};

export const fetchGetStayClassDetail = async (roomType) => {
  try {
    const response = await axios.get(
      `${API_URL}getStayClassDetail/${roomType}`
    );
    // console.log("response.datafrom get stay class detail");
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch stay class detail:", error);
    return null;
  }
};

export const calculateStayClassPrice = async (roomType, data) => {
  try {
    const response = await axios.post(
      `${API_URL}calculateStayClass/${roomType}`,
      data
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error("Failed to calculate stay class price:", error);
    return null;
  }
};

export const getStayClassQuota = async (roomType, start_date, end_date) => {
  try {
    const response = await axios.get(
      `${API_URL}getStayClassQuota/${roomType}?start_date=${start_date}&end_date=${end_date}`
    );
    // console.log("Quota for stay class:");
    return response.data;
  } catch (error) {
    console.error("Failed to fetch stay class quota:", error);
    return null; // return null if fetching fails
  }
};

//ceate handleBookGlamping function that return null
export const handleBookGlamping = async (
  navigate,
  name,
  email,
  phone,
  type,
  room,
  start_date,
  end_date,
  roomType,
  setBookingNumber,
  slug
) => {
  const data = {
    name,
    email,
    phone,
    type,
    room,
    start_date,
    end_date,
  };
  // console.log(data);
  const token = localStorage.getItem("user");
  if (!token) {
    navigate("/login");
    return;
  }
  try {
    const response = await axios.post(`${API_URL}book/${roomType}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let bookingNumber = response.data.data?.transaction.booking_number;
    // console.log("response number from handlebook");
    // console.log(response);
    setBookingNumber(bookingNumber);
    navigate(`/accomodation/${slug}/booker-information/${bookingNumber}`);
  } catch (error) {
    console.error("Failed to book ticket:", error);
  }
};

export const requestPaymentStay = async (
  bookingNumber,
  method,
  bank,
  navigate,
  roomType,
  setTicketQr
) => {
  const token = localStorage.getItem("user");
  try {
    const response = await axios.post(
      `${API_URL}payment/${bookingNumber}`,
      {
        method,
        bank,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("request payment");
    // console.log(response);
    setTicketQr(response.data.data.transaction);
    navigate(`/accomodation/${roomType}/booker-information/payment/va-number`);
    return response.data;
  } catch (error) {
    console.error("Failed to post payment:", error);
    return null;
  }
};

export const getPaymentVA = async (slug, bookingNumber) => {
  const token = localStorage.getItem("user");
  // console.log("bookingNumber");
  // console.log(bookingNumber);
  try {
    const response = await axios.get(`${API_URL}GetPayment/${bookingNumber}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("get payment va");
    // console.log(response);
    return response.data;
  } catch (error) {
    console.error("Failed to get payment va:", error);
    return null;
  }
};

export const fetchPackage = async () => {
  try {
    const response = await axios.get(`${API_URL}getPackageHoliday`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch package holiday:", error);
    return []; // return an empty array if fetching fails
  }
};

export const fetchPackageDetail = async (slug) => {
  try {
    const response = await axios.get(
      `${API_URL}getPackageHolidayDetail/${slug}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch package holiday detail:", error);
    return null; // return null if fetching fails
  }
};

// export const handleBookPackage = async (
//   navigate,
//   name,
//   email,
//   phone,
//   qty,
//   type,
//   setBookingNumber,
//   slug
// ) => {
//   const data = {
//     name,
//     email,
//     phone,
//     qty,
//     type,
//     slug,
//     setBookingNumber,
//   };
//   console.log("ini bisa");
//   console.log(data);
//   const token = localStorage.getItem("user");
//   if (!token) {
//     navigate("/login");
//     return;
//   }
//   try {
//     const response = await axios.post(`${API_URL}book/${slug}`, data, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     let bookingNumber = response.data.data?.transaction.booking_number;
//     console.log("response");
//     console.log(response);
//     setBookingNumber(bookingNumber);
//     navigate(`/accomodation/${slug}/booker-information/${bookingNumber}`);
//   } catch (error) {
//     console.error("Failed to book Package:", error);
//   }
// };

export const handleBookPackage = async (
  data,
  token,
  slug,
  navigate,
  setBookingNumber
) => {
  if (!token) {
    navigate("/login");
    return;
  }

  try {
    const response = await axios.post(`${API_URL}book/${slug}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    let bookingNumber = response.data.data.transaction.booking_number;
    setBookingNumber(bookingNumber);
    // // setBookingNumber(response.data.data.transaction.booking_number);
    // console.log('booking number from handlebook'+bookingNumber);
    // console.log(response.data);
    navigate(`/package-payment/${slug}/payment/${bookingNumber}/`);
  } catch (error) {
    console.error("Failed to book ticket:", error);
  }
};