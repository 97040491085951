import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Navigation from "../../components/Navbar/Navigation";
import "./montainHome.css";
import gunung from "../../assets/bg-mountain-view1.png";
import Banner from "../../components/Banner/Banner";
import img1 from "../../assets/card_background_montain/background1.png";
import img2 from "../../assets/card_background_montain/background2.png";
// import img3 from "../../assets/card_background_montain/background3.png";
// import img4 from "../../assets/card_background_montain/background4.png";
// import img5 from "../../assets/card_background_montain/background5.png";
import loc1 from "../../assets/card_background_montain/location1.jpg";
import loc2 from "../../assets/card_background_montain/location2.jpg";
import BackgroundImgMontain from "../../components/BackgroundImgMontain/BackgroundImgMontain";
import Pills from "../../components/Pills/Pills";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imgLogo from "../../assets/kp-mountain-logo.png";
import Footer from "../../components/Footer/Footer";
import Ticket from "../../components/TiketComponent/TiketComponent";
import ContactComponent from "../../components/ContactComponent/ContactComponent";

import {
  fetchTickets,
  fetchStayAccomodation,
} from "../../services/ticketService";

import { getMaps } from "../../services/mapsService";
import { getRestaurant } from "../../services/restaurant";
import { fetchEvents } from "../../services/eventService";

const facilities = [
  {
    image: img1,
    title: "Serenity Glamping",
    link: "/kalselpark-mountainview/glamping",
  },
  { image: img2, title: "Mountain Cabin", link: "#" },
  { image: img2, title: "Mountain Cabin 2", link: "#" },
  { image: img2, title: "Mountain Cabin 3", link: "#" },
  { image: img2, title: "Mountain Cabin 4", link: "#" },
];

const locations = [
  { image: loc1, title: "Bukit Mawar", location: "Jonggol, Kab. Banjar" },
  { image: loc2, title: "Bukit Langara", location: "Jonggol, Kab. Banjar" },
];
// console.log(facilities);

const MontainHome = () => {
  const [ticket, setTicket] = useState([]);
  const [facilities, setFacilities] = useState([]);
  const [maps, setMaps] = useState([]);
  const [resto, setResto] = useState([]);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const ticket = await fetchTickets();
      const facilities = await fetchStayAccomodation();
      const map = await getMaps();
      const resto = await getRestaurant();
      const event = await fetchEvents();

      setMaps(map.data.data.mapss);
      setResto(resto.data.data.restaurants);

      setTicket(ticket.data.tickets);
      setFacilities(facilities.data.stays);

      setEvents(event.data.events);
    };
    fetchData();
  }, []);

  // console.log("resto");
  // console.log(resto);

  // useEffect(() => {}, []);

  // console.log("facilites");
  // console.log(facilities);
  const settings = {
    dots: true,
    infinite: false,
    arrows: true,
    autoplaySpeed: 2500,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2500,
          speed: 300,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2500,
          speed: 300,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2500,
          speed: 300,
        },
      },
    ],
  };
  return (
    <>
      <Navigation />
      <Banner img={gunung} />
      {/* <Banner imgLogo={imgLogo} img={gunung} /> */}

      <div className="bg2">
        <section className="text-center">
          <div className="container">
            <div className="row2 justify-content-center">
              <div className="col-md-10 col-lg-10">
                <p className="lead">
                  Kalsel Park Mountainview adalah destinasi wisata yang
                  menakjubkan di Tahura Sultan Adam Mandiangin, menawarkan
                  pengalaman memukau di tengah keindahan alam pegunungan. Dengan
                  pemandangan memikat dari hamparan gunung dan atmosfir alam
                  yang menenangkan, tempat ini menjadi tempat ideal untuk
                  melepas penat dan menikmati keindahan alam. Kalsel Park juga
                  menawarkan beragam akomodasi, mulai dari glamping, villa,
                  hingga cabin, memastikan kenyamanan tinggal bagi pengunjung.
                  <br />
                  <br />
                  Ditambah dengan wahana seperti aviary yang mempesona, taman
                  bunga yang menawan, mini outbound yang menantang, dan cafe
                  yang menyajikan pemandangan indah, Kalsel Park menjadi
                  destinasi yang sempurna untuk menghabiskan waktu liburan yang
                  tak terlupakan di tengah keindahan alam yang menakjubkan.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row2">
              <div className="col-12">
                <h4>Fasilitas Kalsel Park Mountainview</h4>
              </div>
            </div>
          </div>
          <div className="dekstop-view">
            <Slider {...settings}>
              {ticket
                .filter((ticket) => ticket.category == "mountainview")
                .map(
                  ({
                    id_ticket,
                    image_path,
                    ticket_name,
                    slug,
                    price,
                    ticket_description,
                  }) => (
                    <div key={id_ticket} className="col-md-3">
                      <div className="facility-container">
                        <BackgroundImgMontain
                          key={id_ticket}
                          image={image_path}
                          title={ticket_name}
                          link={`/ticket-attraction/${slug}`}
                        />
                      </div>
                    </div>
                  )
                )}

              {facilities
                .filter((facilities) => facilities.category == "mountainview")
                .map(
                  ({
                    id_stay,
                    image_path,
                    slug,
                    price,
                    show,
                    // ticket_description,
                  }) => (
                    <div key={id_stay} className="col-md-3">
                      <div className="facility-container">
                        <BackgroundImgMontain
                          key={id_stay}
                          image={show.image[0].image_path}
                          title={show.title}
                          link={`/accomodation/${slug}`}
                        />
                      </div>
                    </div>
                  )
                )}

              {maps
                .filter((map) => map.category == "mountainview")
                .map(
                  ({
                    id_stay,
                    image_path,
                    slug,
                    price,
                    show,
                    image,
                    // ticket_description,
                  }) => (
                    <div key={id_stay} className="col-md-3">
                      <div className="facility-container">
                        <BackgroundImgMontain
                          key={id_stay}
                          image={image[0].image_path}
                          title={show.title}
                          link={`/maps/${slug}`}
                        />
                      </div>
                    </div>
                  )
                )}

              {resto
                .filter((map) => map.category == "mountainview")
                .map(
                  ({
                    id_stay,
                    image_path,
                    slug,
                    price,
                    show,
                    image,
                    // ticket_description,
                  }) => (
                    <div key={id_stay} className="col-md-3">
                      <div className="facility-container">
                        <BackgroundImgMontain
                          key={id_stay}
                          image={image[0].image_path}
                          title={show.title}
                          link={`/maps/${slug}`}
                        />
                      </div>
                    </div>
                  )
                )}

              {events
                .filter((map) => map.category == "mountainview")
                .map(
                  ({
                    id_event,
                    image,
                    slug,
                    price,
                    show,
                    // ticket_description,
                  }) => (
                    <div key={id_event} className="col-md-3">
                      <div className="facility-container">
                        <BackgroundImgMontain
                          key={id_event}
                          image={image[0].image_path}
                          title={show.title}
                          link={`/events/${slug}`}
                        />
                      </div>
                    </div>
                  )
                )}
            </Slider>
          </div>
        </section>
        <Ticket />
        <ContactComponent />
        <section className="text-center">
          <div className="container">
            <div className="row2">
              <div className="col-12">
                <h4>Obyek Wisata Pegunungan Lainnya</h4>
              </div>
            </div>
            <div className="row justify-content-center">
              {locations.map((loc, index) => (
                <div
                  key={loc.title + index}
                  className="col-md-6 d-flex justify-content-center"
                  key={index}
                >
                  <Pills
                    image={loc.image}
                    title={loc.title}
                    location={loc.location}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
};

export default MontainHome;
