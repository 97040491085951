import React, { useEffect, useState } from 'react'
import { fetchPackageDetail } from '../../services/ticketService';
import Navigation from '../../components/Navbar/Navigation'
import Banner from "../../components/Banner/Banner";
import Footer from '../../components/Footer/Footer'
import './packageDetails.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { BeatLoader } from 'react-spinners';
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import RoomCounter from '../../components/RoomCounter/RoomCounter';
import {
    setJumlahPackage,
    setTotalCostPackage,
    resetPackageState,
} from "../../features/package/packageSlice";
import PackageCounter from '../../components/PackageCounter/PackageCounter';
import { isLoggedIn } from "../../features/login/loginSlice";

const PackageDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const jumlahPackage = useSelector((state) => state.package.jumlahPackage);
    const totalCostPackage = useSelector((state) => state.package.totalCostPackage);
    const [detailPackage, setDetailPackage] = useState(null);
    const [imgPackagesGallery, setImgPackagesGallery] = useState([]);
    const [totalCostP , setTotalCostP] = useState(0);
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    // console.log('image_galller:' , imgPackagesGallery);
    const { slug } = useParams();
    
    useEffect(() => {
        dispatch(resetPackageState());
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const packages = await fetchPackageDetail(slug);
            // console.log('ini packages:', packages);
            setDetailPackage(packages.data.packageHoliday);
            setImgPackagesGallery(packages.data.packageHoliday.gallery);
            setTotalCostP(packages.data.packageHoliday.price);
        }
        fetchData();
    }, [slug])
    const images = imgPackagesGallery?.map((item) => ({
        original: item.img_gallery,
        thumbnail: item.img_gallery,
    }))

    useEffect(() => {
       const total = jumlahPackage * totalCostP;
       dispatch(setTotalCostPackage(total));
    }, [jumlahPackage, totalCostP, dispatch]);

    return (
        <>
            <Navigation />
            <Banner className="banner"
                imgLogo={detailPackage && detailPackage.gallery[0]?.img_gallery}
                img={detailPackage && detailPackage.gallery[0]?.img_gallery}
                data-overlay="3"
            />
            <div className='bg'>
                <div className='container'>
                    <div className="mainContentGunung grid"></div>
                    <div className='articleMountain'>
                        <h2>{detailPackage?.title ?? <BeatLoader />}</h2>
                        <br/>
                        <div className='inline'>
                            <ImgGallery items={images} />
                            <div className='block'>
                                <p>{detailPackage?.description.replace(/<\/?[^>]+(>|$)/g, '').replace(/&quot;/g, '"').replace(/&#39;/g, "'") ?? <BeatLoader />}</p>
                                <h4 className='titleDescription'>Itinerary</h4>
                                {detailPackage?.itinerary ? (
                                    <div dangerouslySetInnerHTML={{ __html: detailPackage?.itinerary }} />
                                ) : (
                                    <BeatLoader />
                                )}
                                {/* <div dangerouslySetInnerHTML={{ __html: detailPackage?.itinerary ?? '<p><BeatLoader /></p>' }} /> */}
                                <div className='person'>
                                    <h4>Person</h4>
                                    <PackageCounter value={jumlahPackage} onChange={setJumlahPackage} />                               
                                </div>
                                <h2 style={{ textAlign: "center" }}>
                                    Total Rp {totalCostPackage},-
                                </h2>
                                <div style={{textAlign: "center"}}>
                                    <p>*Harga belum termasuk biaya admin</p>
                                    {isLoggedIn ? (
                                        <button
                                            className="btn"
                                            style={{ background: "#30552f", color: "white" }}
                                            disabled={
                                                totalCostPackage === 0
                                            }
                                            onClick={() => navigate(`/package/${slug}/booker-information`)}
                                        >
                                            Book Now
                                        </button>
                                    ) : (
                                        <button
                                            className="btn"
                                            style={{ background: "#30552f", color: "white" }}
                                            onClick={() => navigate("/login")}
                                        >
                                            Login to Book
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default PackageDetail