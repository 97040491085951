import React from 'react'
import "./imageGallery.scss";
import ImageGallery from "react-image-gallery";

const ImgGallery = ({ items }) => {
  return (
    <div className="gallery-img">
      <ImageGallery items={items} />
    </div>
  )
}

export default ImgGallery