import React, { useState, useRef, useEffect, useCallback } from "react";
import "./login.css";
import kalselLogo from "../../assets/kalsel-logo.png";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import Navigation from "../../components/Navbar/Navigation";
import AuthService from "../../services/authService";
import { BeatLoader } from "react-spinners";

const Login = () => {
  const navigate = useNavigate();
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isVerified, setIsVerified] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    // console.log(email.current, password.current);
    setIsLoading(true); // Start loading

    try {
      const email = emailRef.current.value;
      const password = passwordRef.current.value;
      const response = await AuthService.login(email, password);
      navigate("/");
      // window.location.reload();
    } catch (error) {
      // console.log(error);
      if (error.response && error.response.status === 402) {
        setMessage("Verify your email first");
      } else {
        setMessage("Invalid email or password");
      }
      setIsLoading(false); // Stop loading
    }
  });

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="login section">
        <div className="loginCard container">
          <div className="logoDiv" onClick={() => navigate("/")}></div>
          <form onSubmit={handleLogin}>
            <div className="cardContent grid">
              <div className="cardText">
                <h3>Login</h3>
                <p>Masukkan email dan password yang sudah terdaftar</p>
              </div>

              <div className="cardLogin grid">
                <div className="emailDiv">
                  <label htmlFor="email">Email</label>
                  <input
                    // type="text"
                    // value={email.current}
                    ref={emailRef}
                    type="email"
                    id="email"
                    name="email"
                    // onChange={(e) => (email.current = e.target.value)}
                    placeholder="Email"
                  />
                </div>

                <div className="passwordDiv">
                  <label htmlFor="password">Password</label>
                  <input
                    // type="password" placeholder="Password"
                    // type="password"
                    // value={password.current}
                    type="password"
                    id="password"
                    name="password"
                    ref={passwordRef}
                    // onChange={(e) => (password.current = e.target.value)}
                    placeholder="Password"
                  />
                </div>
              </div>
              <button type="submit" className="btn" disabled={isLoading}>
                {isLoading ? <BeatLoader /> : "Login"}
              </button>
              <p>
                Belum Punya Akun? Daftar{" "}
                <a
                  style={{ color: "green", fontWeight: "bold" }}
                  onClick={() => navigate("/register")}
                >
                  disini
                </a>{" "}
              </p>
              {message && <div className="error">{message}</div>}
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Login;
