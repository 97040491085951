import React, { useContext, useState, useEffect } from "react";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import "./ticketPaymentVa.css";
import BankCheckbox from "../../components/Banks/Banks";
import { requestPayment } from "../../services/ticketService";
import { fetchingBookingSummary } from "../../services/ticketService";
import BeatLoader from "react-spinners/BeatLoader"; // Import BeatLoader for loading animation

// import AuthService from "../../services/authService";
import { AppContext } from "../../App";
import QRISCheckbox from "../../components/Banks/QRISCheckbox";
// import format from "date-fns/format";
// import {fetchingBookingSummary} from "../../services/ticketService/fetchingBookingSummary";

const TicketPaymentVa = ({ attraction }) => {
  const { slug, bookingNumber } = useParams();
  const navigate = useNavigate();
  let [summaryData, setSummaryData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { selectedDate, selectedBank, setSelectedBank, setTicketQr, method } =
    useContext(AppContext);

  // console.log("selectedBank");
  // console.log(selectedBank);
  // console.log("booingNumber");
  // console.log(bookingNumber);
  // let method = "va";
  let bank = selectedBank;

  // useEffect(() => {
  //   if (bookingNumber === null) {
  //     navigate(`/ticket-attraction/${slug}`);
  //   }
  // }, [bookingNumber, slug, navigate]);

  function formatDate(dateString) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // if(bookingNumber === null){
        //   const summary = await fetchingBookingSummary(bookingNumber);
        //   fetchingBookingSummary(summary);
        //   setSummaryData(summary);
        // }else{
        //   navigate(`/ticket-attraction/${slug}`);
        // }
        setSelectedBank(null);

        const summary = await fetchingBookingSummary(bookingNumber);
        fetchingBookingSummary(summary);
        setSummaryData(summary);
      } catch (error) {
        if ((error.response && error.response.status === 401) || 403) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          // setError("Failed to fetch profile");
          window.location.href = "/login";
        }
      }
      // const summary = await fetchingBookingSummary(bookingNumber);
      // fetchingBookingSummary(summary);
      // setSummaryData(summary);
    };
    fetchData();
  }, [bookingNumber]);

  // const token = localStorage.getItem("user");
  // const date = selectedDate.toISOString().split("T")[0];
  // const data = {
  //   name: summaryData && summaryData.data?.transaction.booker_name,
  //   email: summaryData && summaryData.data?.transaction.email,
  //   qty: summaryData && summaryData.data?.transaction.jumlah_ticket,
  //   date: date,
  //   phone: summaryData && summaryData.data?.transaction.phone,
  // };

  // console.log("summaryData-----");
  // console.log(summaryData && summaryData.data?.transaction);
  // console.log("summaryData-----");
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Payment</h1>
        <div className="bg-booking-va">
          <div className="bg-white">
            <h5>Summary</h5>
            <div className="summary-bg">
              <span>
                Jumlah Tiket:{" "}
                {summaryData && summaryData.data?.transaction.jumlah_ticket}
              </span>
              <span>
                Tanggal Masuk:{" "}
                {summaryData &&
                  formatDate(summaryData.data?.transaction.checkin_date)}
              </span>
              <span>
                Total Harga: Rp{" "}
                {summaryData && summaryData.data?.transaction.total_harga},-
              </span>
            </div>

            <div className="summary-bg">
              <span>
                Nama: {summaryData && summaryData.data?.transaction.booker_name}
              </span>
              <span>
                Email:{summaryData && summaryData.data?.transaction.email}
              </span>
              <span>
                No. Telepon:{" "}
                {summaryData && summaryData.data?.transaction.phone}
              </span>
            </div>
            <span className="info-va">
            Pembayaran dapat dilakukan dengan metode pembayaran berikut.
            </span>
            <div className="banks-va">
              <div className="payment-method">
                {/* <span>Virtual Account</span> */}
                <BankCheckbox />
              </div>
            </div>

            <button
              disabled={!selectedBank}
              className="btn btn-book"
              onClick={() =>
                requestPayment(
                  bookingNumber,
                  method,
                  bank,
                  navigate,
                  slug,
                  setTicketQr
                )
              }
            >
              Book
            </button>
            <span className="need-info">
              <a href="#">Butuh bantuan?</a>{" "}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TicketPaymentVa;
