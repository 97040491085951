import React, { useEffect, useState } from "react";
import "./popular.css";

import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import img1 from "../../assets/sungai-1.avif";
import img2 from "../../assets/mountain-adventure.png";
import img3 from "../../assets/bg-popular.png";
import img4 from "../../assets/jazz-festival.png";
import paralayang from "../../assets/paralayang.png";
import forestoutbound from "../../assets/forest-outbound.png";
import { fetchEvents } from "../../services/eventService";
// import img5 from "../../assets/history-1.avif";
// import img6 from "../../assets/xfactor-1.avif";

import { useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

// const Data = [
//   {
//     id: 1,
//     imgSrc: img4,
//     descTitle: "Forest Jazz Festival",
//     date: "10th May 2024",
//     description: "unknown",
//     navigation: "/coming-soon",
//   },
//   {
//     id: 2,
//     imgSrc: img2,
//     descTitle: "Forest Adventure",
//     date: "12th June 2024",
//     description: "Relaxation",
//     navigation: "/coming-soon",
//   },
// ];

const Data2 = [
  {
    id: 3,
    imgSrc: paralayang,
    descTitle: "Forest Outbound",
    description: "Relaxation",
    navigation: "/coming-soon",
  },

  {
    id: 4,
    imgSrc: forestoutbound,
    descTitle: "Paralayang Adventure",
    description: "Relaxation",
    navigation: "/coming-soon",
  },
];

const Popular = () => {
  const [events, setEvents] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const events = await fetchEvents();
      setEvents(events?.data?.events);
    };
    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <div className="bg-img-popular">
      <section className="popular section container">
        <div className="secContainer">
          <div className="secHeader flex">
            <div className="textDiv">
              {/* <h2 className="secTitle">Popular Activity</h2> */}
              <h2 className="secTitle">What's Hot</h2>
              <p>Nikmati beragam paket wisata favorit yang memukau.</p>
            </div>

            {/* <div  className="iconsDiv flex">
            <BsArrowLeftShort className="icon leftIcon" />
            <BsArrowRightShort className="icon rightIcon" />
          </div> */}
          </div>
          <div className="displayFlex">
            <h4>Events/Programs</h4>
            <div className="mainContent grid">
              {events.length === 0 ? (
                <p>No Events</p>
              ) : (
                events.map((item) => {
                  return (
                    <div
                      key={item.id_event}
                      data-aos="fade-up"
                      className="singleDestination"
                    >
                      <div
                        className="destImage"
                        onClick={() => navigate(`/events/${item.slug}`)}
                      >
                        <img src={item.image[0].image_path} alt="Image Title" />
                        <div className="overlayInfo">
                          <h3>{item.title}</h3>
                          <BsArrowRightShort className="icon" />
                        </div>
                      </div>

                      <div className="destFooter">
                        <div className="destText flex">
                          <h6>{item.title}</h6>
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>

            <h4>Attraction</h4>
            <div className="mainContent grid">
              {Data2.map((item) => {
                return (
                  <div
                    key={item.id}
                    data-aos="fade-up"
                    className="singleDestination"
                  >
                    <div
                      className="destImage"
                      onClick={() => navigate(item.navigation)}
                    >
                      <img src={item.imgSrc} alt="Image Title" />

                      <div className="overlayInfo">
                        <h3>{item.descTitle}</h3>
                        <BsArrowRightShort className="icon" />
                      </div>
                    </div>

                    <div className="destFooter">
                      {/* <div className="number">0{id}</div> */}

                      <div className="destText flex">
                        <h6>{item.descTitle}</h6>
                        {/* <span className="flex">
                      <span className="dot">
                        <BsDot className="icon" />
                      </span>
                      Dot
                    </span> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Popular;
