import React, { useState, memo } from "react";
import "./detailPack.css";
import "../../components/NightCounter/nightCounter.css";
import "../../components/PersonCounter/personCounter.css";
import Navigation from "../../components/Navbar/Navigation";
import img1 from "../../assets/glamping-1.webp";
import DatePicker from "../../components/Date/Date";
import NightCounter from "../../components/NightCounter/NightCounter";
import PersonCounter from "../../components/PersonCounter/PersonCounter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
// import { faPenNib } from "@fortawesome/free-solid-svg-icons";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faPerson,
  faMoon,
  faToilet,
  faBed,
  faTemperatureLow,
  faHouseLock,
} from "@fortawesome/free-solid-svg-icons";

const DetailPackage = () => {
  const navigate = useNavigate();
  let [total, setTotal] = useState(150000);

  let [count, setCount] = useState(1);
  let [person, setPerson] = useState(1);

  function grandTotal() {
    total = person * count * 150000;
    setTotal(total);
  }

  function calculate(count, person) {
    total = count * person * 150000;
    return setTotal(total);
  }

  function incrementCount() {
    count = count + 1;
    setCount(count);
    calculate(count, person);
  }

  function decrementCount() {
    count = count - 1;
    setCount(count);
    calculate(count, person);
  }

  function incrementPerson() {
    person = person + 1;
    setPerson(person);
    calculate(count, person);
  }

  function decrementPerson() {
    person = person - 1;
    setPerson(person);
    calculate(count, person);
  }

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="detailPackage section container">
        <div className="mainContent">
          <div className="singleDestination">
            <div className="destImage">
              <img src={img1} alt="Image Title" />
            </div>
          </div>
          <div className="secContainer">
            <div className="secHeader flex">
              <div className="textDiv">
                <h1 className="secTitle">Glamping</h1>
              </div>
            </div>
          </div>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </p>
          <div className="facilities">
            <h3>Faclities</h3>
            <ul className="list-facilities">
              <li>
                2 Beds <FontAwesomeIcon icon={faBed} />
              </li>
              <li>
                Bathroom <FontAwesomeIcon icon={faToilet} />
              </li>
              <li>
                Air Conditioner <FontAwesomeIcon icon={faTemperatureLow} />
              </li>
              <li>
                24/7 Security <FontAwesomeIcon icon={faHouseLock} />
              </li>
            </ul>
          </div>
          <div className="detailBookingPr">
            <div className="detailBooking">
              <div className="checkin">
                <h3>Check-In Date</h3>
                <DatePicker />
                <p className="cico">
                  *Checkin before 14.00 WITA and checkout before 12.00 WITA
                </p>
              </div>

              <div className="night">
                <div className="inline">
                  <h3>Nights&nbsp;</h3>
                  <FontAwesomeIcon className="icon" icon={faMoon} />
                </div>

                {/* <NightCounter /> */}
                <section className="counter container">
                  <button
                    className=" btnDecreament"
                    onClick={decrementCount}
                    disabled={count === 1}
                  >
                    -
                  </button>
                  <span className="count"> {count}</span>
                  <button className="btnIncrement" onClick={incrementCount}>
                    +
                  </button>
                </section>
                {/* ============================= */}
              </div>

              <div className="person">
                <div className="inline">
                  <h3>Person&nbsp;</h3>
                  <FontAwesomeIcon className="icon" icon={faPerson} />
                </div>

                {/* <PersonCounter /> */}
                <section className="personCounter container">
                  <button
                    className="btnIncrement"
                    onClick={decrementPerson}
                    disabled={person === 1}
                  >
                    -
                  </button>
                  <span className="person"> {person}</span>
                  <button className="btnDecreament" onClick={incrementPerson}>
                    +
                  </button>
                </section>
                {/* ==================== */}
              </div>

              {/* <button onClick={grandTotal}>calculate</button> */}
              <h2> Total: Rp {total},-</h2>
              <span>*(Tax Included)</span>
            </div>
          </div>
          <button onClick={() => navigate("/summary")} className="btn">
            Book Now
          </button>
        </div>
      </section>
    </>
  );
};

export default memo(DetailPackage);
