import React from "react";
import "./summary.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPerson,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";

const Summary = () => {
  const navigate = useNavigate();
  return (
    <section className="summary section container">
      <div className="detailSummaryMaxwidth">
        <h3 onClick={() => navigate("/detailPackage")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Summary</h1>
        <div className="detailSummary">
          <span>Detail Booking</span>
          <p>
            Checkin on <b>29/01/2024</b>{" "}
          </p>
          <p>Rp 300000,-</p>
          <p>
            2 Person(s) <FontAwesomeIcon className="icon" icon={faPerson} />
          </p>
          <p>
            2 Night(s) <FontAwesomeIcon className="icon" icon={faMoon} />
          </p>
        </div>
        <div className="bookerInfo">
          <span>Booker Information</span>
          {/* <p>Name</p> */}
          <input type="text" placeholder="Name" />
          {/* <p>Phone</p> */}
          <input type="text" placeholder="Phone" />
          {/* <p>Email</p> */}
          <input type="email" placeholder="Email" />
        </div>
        <p className="p-note">*Make sure the data you input is correct</p>
        <button onClick={() => navigate("/payment")} className="btn">
          Next
        </button>
      </div>
    </section>
  );
};

export default Summary;
