import React, { useEffect, useState } from "react";
import "./style.css";

const Clock = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    localStorage.getItem("mode") === "Dark Mode"
  );

  // useEffect(() => {
  //   document.body.classList.toggle("dark", isDarkMode);
  //   localStorage.setItem("mode", isDarkMode ? "Dark Mode" : "Light Mode");
  // }, [isDarkMode]);

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const secToDeg = (date.getSeconds() / 60) * 360;
      const minToDeg = (date.getMinutes() / 60) * 360;
      const hrToDeg = (date.getHours() / 12) * 360;

      document.querySelector(
        ".second"
      ).style.transform = `rotate(${secToDeg}deg)`;
      document.querySelector(
        ".minute"
      ).style.transform = `rotate(${minToDeg}deg)`;
      document.querySelector(".hour").style.transform = `rotate(${hrToDeg}deg)`;
    };

    const interval = setInterval(updateTime, 1000);
    updateTime(); // Initialize the time immediately

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <div className="all">
      <div className="body-clock">
        <div className="container-clock">
          <div className="clock">
            {[...Array(12)].map((_, i) => (
              <label key={i} style={{ "--i": i + 1 }}>
                <span>•</span>
              </label>
            ))}
            <div className="indicator">
              <span className="hand hour"></span>
              <span className="hand minute"></span>
              <span className="hand second"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clock;
