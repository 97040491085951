import React, { useEffect } from "react";
import "./explorepage.css";

import Carousel from "react-bootstrap/Carousel";

// import { BsArrowLeftShort } from "react-icons/bs";
// import { BsArrowRightShort } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import img1 from "../../assets/sungai-1.avif";
// import img2 from "../../assets/hutan-1.jpeg";
// import img3 from "../../assets/gunung-1.avif";
// import img4 from "../../assets/budaya-1.avif";
// import img5 from "../../assets/history-1.avif";
// import img6 from "../../assets/xfactor-1.avif";
import img7 from "../../assets/mountain-banner.jpeg";

// import img1g from "../../assets/geopark-logo.jpeg";
// import img2g from "../../assets/kalsel.jpeg";
// import img3g from "../../assets/kemenkraf.jpeg";

import { useNavigate } from "react-router-dom";
import { BsStarFill, BsFillPinMapFill } from "react-icons/bs";

const ExplorePage = () => {
  const navigate = useNavigate();
  return (
    <div className="backgroundImg">
    
    <section className="explorenow section container">
      <div className="secContainers">
        <div className="secHeader flex">
          <div className="textDiv">
            {/* <h2 className="secTitle">Popular Activity</h2> */}
            <h2 className="secTitle">Explore Now!</h2>
            <p>
              Lorem Ipsum dolor sit amet, concestur adipiscing elit. Urna,
              tortor tempus.
            </p>
          </div>

          {/* <div  className="iconsDiv flex">
            <BsArrowLeftShort className="icon leftIcon" />
            <BsArrowRightShort className="icon rightIcon" />
          </div> */}
        </div>
        <div className="secHeader flex"></div>
      </div>
      <div className="carousel">
      <Carousel>
          <Carousel.Item>
            <div className="carouselStyling">
              <div className="carouselContent">
                <div className="col-md-6">
                  <img className="carouselImg" src={img7} alt="Mountain Area" />
                </div>                  
                <div className="col-md-6">
                  <div className="locationList">
                    <h3>Mountain Area</h3>
                    <ul>
                      <li>
                        <BsStarFill className="icon" /> Kalsel Park Mountainview
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit Mawar
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit Langgara
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit ABCD
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Gunung DEFG
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Gunung HIJK
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item>
          {/* <Carousel.Item>
            <div className="carouselStyling">
              <div className="carouselContent">
                <div className="col-md-6">
                  <img className="carouselImg" src={img1} alt="Mountain Area" />
                </div>
                <div className="col-md-6">
                  <div className="locationList">
                    <h3>Mountain Area Slide 2</h3>
                    <ul>
                      <li>
                        <BsStarFill className="icon" /> Kalsel Park Mountainview
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit Mawar
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit Langgara
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Bukit ABCD
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Gunung DEFG
                      </li>
                      <li>
                        <BsFillPinMapFill className="icon" /> Kalsel Park Gunung HIJK
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Item> */}
      </Carousel>
      </div>
    </section>
    </div>
  );
};

export default ExplorePage;
