import React from "react";
import Navigation from "../../components/Navbar/Navigation";
import Banner from "../../components/Banner/Banner";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
// import DatePicker from "../../components/Date/Date";
// import PersonCounter from "../../components/PersonCounter/PersonCounter";
import NightCounter from "../../components/NightCounter/NightCounter";
import "./mountainGlamping.css";
import imgLogo from "../../assets/kp-mountain-logo.png";
import gunung from "../../assets/bg-mountain-view.png";
import img1 from "../../assets/bukit-langgara.jpg";
import img2 from "../../assets/kahung.jpg";
import img3 from "../../assets/martapura.jpeg";
import img4 from "../../assets/mandiangin-1.jpg";
import { useSelector, useDispatch } from "react-redux";
import {
  // setCheckinDate,
  // setPerson,
  setNights,
  setRoomType,
} from "../../features/glamping/glampingSlice";

const Data = [
  {
    id: 1,
    imgSrc: img1,
    descTitle: "Bukit Langgara",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/forest-page",
  },
  {
    id: 2,
    imgSrc: img2,
    descTitle: "Kahung",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 3,
    imgSrc: img3,
    descTitle: "Martapura",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 4,
    imgSrc: img4,
    descTitle: "Mandiangin",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
];

const GunungPageBooking = () => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  // const checkinDate = useSelector((state) => state.glamping.checkinDate);
  const person = useSelector((state) => state.glamping.person);
  const nights = useSelector((state) => state.glamping.nights);
  const roomType = useSelector((state) => state.glamping.roomType);
  // console.log("roomtype: " + roomType);
  // const setPerson = (person) => dispatch(setPerson(person));
  // const [person, setPerson] = useState(1);
  // const [nights, setNights] = useState(1);
  const costPerNightPerPerson = 150000; // Example cost per night per person
  const totalCost = person * nights * costPerNightPerPerson;
  const images = Data.map((item) => ({
    original: item.imgSrc,
    thumbnail: item.imgSrc,
  }));
  return (
    <>
      <Navigation />
      <Banner imgLogo={imgLogo} img={gunung} />

      <div className="bg">
        <div className="container">
          <div className="mainContentGunung grid"></div>

          <div className="articleMountain">
            <h4>Serenity Glamping</h4>
            <div className="inline">
              <ImgGallery items={images} />
              <div className="block">
                <p>
                  Tiba di Kalselpark, Anda disambut oleh gemerlap cahaya
                  matahari yang merayap di antara pepohonan tropis yang
                  menjulang tinggi. Di tengah-tengah hamparan alam yang memukau
                  ini, terletaklah paket glamping penginapan yang menawarkan
                  pengalaman tak terlupakan. Menyatu dengan alam namun tetap
                  menyajikan kenyamanan modern, paket glamping Kalselpark
                  menghadirkan tenda-tenda elegan.
                </p>
                <div className="dateRange">
                  <h4>Check-in Date</h4>
                  {/* <DatePicker onDateChange={dispatch(setCheckinDate)} /> */}
                  {/* <DatePicker
                    onDateChange={(date) => dispatch(setCheckinDate(date))}
                  /> */}
                  *Checkin before 14.00 WITA and checkout before 12.00 WITA
                </div>
                <div>
                  <h4 className="dateRange">Room Type</h4>
                  <form>
                    <div className="flex-roomType">
                      <div className="roomType">
                        <label>
                          <input
                            type="radio"
                            name="roomType"
                            value="family"
                            checked={roomType === "family"}
                            onChange={(e) =>
                              dispatch(setRoomType(e.target.value))
                            }
                          />
                          {" "}Family
                        </label>
                      </div>
                      <div className="roomType">
                        <label>
                          <input
                            type="radio"
                            name="roomType"
                            value="deluxe"
                            checked={roomType === "deluxe"}
                            onChange={(e) =>
                              dispatch(setRoomType(e.target.value))
                            }
                          />
                          {" "}Deluxe
                        </label>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="person">
                  <h4>Person</h4>
                  {/* <PersonCounter
                    value={person}
                    onChange={(value) => dispatch(setPerson(value))}
                  /> */}
                </div>
                <div className="night">
                  <h4>Night</h4>
                  <NightCounter
                    value={nights}
                    onChange={(value) => dispatch(setNights(value))}
                  />
                </div>
                <h2>Total Rp {totalCost.toLocaleString()} ,-</h2>
                *(Tax Included)
                <button
                  className="btn"
                  style={{ background: "#30552f", color: "white" }}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GunungPageBooking;
