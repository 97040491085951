// import React from "react";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  fetchStayAccomodationDetail,
  fetchGetStayClassDetail,
  calculateStayClassPrice,
  getStayClassQuota,
} from "../../services/ticketService";
import { format } from "date-fns";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navbar/Navigation";
import Banner from "../../components/Banner/Banner";
import imgLogo from "../../assets/kp-mountain-logo.png";
import gunung from "../../assets/bg-mountain-view.png";
import { BeatLoader } from "react-spinners";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import DateRange from "../../components/DateRange/DateRange";
// import NightCounter from "../../components/NightCounter/NightCounter";
import RoomCounter from "../../components/RoomCounter/RoomCounter";
import "./glampingDetail.css";

import { useSelector, useDispatch } from "react-redux";
import {
  setRoom,
  setNights,
  setRoomType,
  setTotalCost,
} from "../../features/glamping/glampingSlice";
import {isLoggedIn} from "../../features/login/loginSlice";

const GlampingDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const room = useSelector((state) => state.glamping.room);
  const night = useSelector((state) => state.glamping.nights);
  const totalCost = useSelector((state) => state.glamping.totalCost);
  const start_date = useSelector((state) => state.glamping.startDate);
  const end_date = useSelector((state) => state.glamping.endDate);
  const roomType = useSelector((state) => state.glamping.roomType);
  // console.log("roomtype: " + roomType);

  // const costPerNightPerPerson = 150000; // Example cost per night per person
  // const totalCost = person * nights * costPerNightPerPerson;
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const { slug } = useParams();
  const [detailStay, setDetailStay] = useState(null);
  const [imgStay, setImgStay] = useState([]);
  const [stayClasses, setStayClasses] = useState([]);
  const [classDetail, setClassDetail] = useState(null); // State to hold the fetched class detail
  const [quotaStay, setQuotaStay] = useState([]);
  const [quotaStay30, setQuotaStay30] = useState([]);
  const [openAvailable, setOpenAvailable] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const stay = await fetchStayAccomodationDetail(slug);
      setDetailStay(stay.data);
      setImgStay(stay.data.stay.show.image);
      setStayClasses(stay.data.stay.class);
    };
    fetchData();
  }, [slug]);

  useEffect(() => {
    const fetchClassDetail = async () => {
      if (roomType) {
        // Ensure roomType is not null or undefined
        const classDetail = await fetchGetStayClassDetail(roomType);
        setClassDetail(classDetail); // Set the fetched class detail to state
      }
    };
    fetchClassDetail();
  }, [roomType]);

  // calculate total cost
  useEffect(() => {
    const calculatePrice = async () => {
      if (roomType && room > 0) {
        // Assuming you have a way to construct the data needed for calculateStayClassPrice
        const data = {
          start_date: start_date,
          end_date: end_date,
          room: room,
          // Add any other data required by the API
        };
        try {
          const priceResult = await calculateStayClassPrice(roomType, data);
          if (priceResult) {
            // Assuming priceResult contains the total cost directly
            dispatch(setTotalCost(priceResult.data.priceArr.total));
          }
        } catch (error) {
          console.error("Failed to calculate stay class price:", error);
          // Handle error, maybe set totalCost to 0 or keep the old value
        }
      }
    };
    calculatePrice();
  }, [roomType, room, start_date, end_date, calculateStayClassPrice, dispatch]);

  // check quota room from today to selected date
  useEffect(() => {
    const checkQuotaRoom = async () => {
      try {
        const endDateObj = new Date(end_date);
        const endDateObj2 = new Date(endDateObj);
        endDateObj2.setDate(endDateObj.getDate() - 1);
        const endDateStr = endDateObj2.toISOString().split("T")[0];
        // console.log("endDateStr");
        // console.log(endDateStr);
        // dispatch(RoomCounter(1));
        dispatch(setRoom(1));

        const quota = await getStayClassQuota(roomType, start_date, endDateStr);
        setQuotaStay(quota);
      } catch (error) {
        console.error("Failed to check room quota:", error);
      }
    };
    checkQuotaRoom();
  }, [roomType, start_date, end_date]);

  // check quota room from today to selected date
  useEffect(() => {
    const checkQuotaRoom = async () => {
      try {
        // // Convert start_date to a Date object
        const startDateObj = new Date(start_date);
        const startDate = format(new Date(), "yyyy-MM-dd");
        // console.log("start_date");
        // console.log(startDate);
        // // Create a new Date object for 30 days after start_date
        const endDateObj = new Date(startDateObj);
        endDateObj.setDate(startDateObj.getDate() + 60);

        // // Format endDateObj back to a string if necessary, assuming YYYY-MM-DD format
        const endDateStr = endDateObj.toISOString().split("T")[0];
        // console.log("end_date");
        // console.log(endDateStr);

        const quota = await getStayClassQuota(roomType, startDate, endDateStr);
        setQuotaStay30(quota);
      } catch (error) {
        console.error("Failed to check room quota:", error);
      }
    };
    checkQuotaRoom();
  }, [roomType]);

  // console.log("quotaaa stayy");
  // console.log(quotaStay);
  // console.log("quotaaa stayy 30");
  // console.log(quotaStay30);

  const images = imgStay.map((item) => ({
    original: item.image_path,
    thumbnail: item.image_path,
  }));

  const isAnyQuotaUnavailable = quotaStay?.data?.quota.some(
    (item) => item.available === 0
  );

  const handleClickAvailable = () => setOpenAvailable((prev) => !prev);

  // MODAL POP UP
  const [modal, setModal] = useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  //find minimum available room in quotaStay
  const minAvailable = Math.min(
    ...(quotaStay.data?.quota ?? []).map((item) => item.available)
  );
  // console.log("minAvailable");
  // console.log(minAvailable);

  return (
    <>
      <Navigation />
      <Banner imgLogo={imgLogo} img={gunung} />

      <div className="bg">
        <div className="container">
          {/* <div className="mainContentGunung grid"></div> */}

          <div className="articleMountain">
            <h2>{detailStay?.stay?.show?.title ?? <BeatLoader />}</h2>
            <div className="inline">
              <ImgGallery items={images} />
              <div className="block">
                <p>{detailStay?.stay?.show?.description ?? <BeatLoader />}</p>
                <div className="dateRange">
                  <h4>Check-in Date</h4>
                  {/* <DatePicker onDateChange={dispatch(setCheckinDate)} /> */}
                  {/* <DatePicker onDateChange={(date) => dispatch(setCheckinDate(date))} */}
                  <DateRange quotaStay={quotaStay} />
                  *Checkin sebelum 14.00 WITA dan checkout sebelum 12.00 WITA
                  {/* <div className="quotaDisplay">
                    {Array.isArray(quotaStay) &&
                      quotaStay.map((quota, index) => (
                        <div key={index}>
                          <p>Date: {quota.date}</p>
                          <p>Available: {quota.available}</p>
                        </div>
                      ))}
                  </div> */}
                  {/* <div className="quotaInfo">
                    <h4>Room Quota Information</h4>
                    {quotaStay?.data?.quota.map((item, index) => (
                      <div key={index}>
                        <p>Tanggal: {item.date}</p>
                        <p>Ruangan Tersedia: {item.available}</p>
                      </div>
                    ))}
                  </div> */}
                </div>
                <div>
                  <h4 className="dateRange">Room Type</h4>
                  <form>
                    <div className="flex-roomType">
                      {stayClasses.map((stayClass, index) => (
                        <div key={index} className="roomType">
                          <label>
                            <input
                              type="radio"
                              name="roomType"
                              value={stayClass.slug}
                              checked={roomType === stayClass.slug}
                              onChange={(e) =>
                                dispatch(setRoomType(stayClass.slug))
                              }
                            />{" "}
                            {stayClass.show.title}
                          </label>
                        </div>
                      ))}
                    </div>
                  </form>
                </div>
                <div style={{ paddingLeft: "1.5rem", paddingRight: "1.5rem" }}>
                  <button onClick={toggleModal} className="btn">
                    Cek Ketersediaan
                  </button>
                </div>
                <div className="person">
                  <h4>Room</h4>
                  <RoomCounter
                    value={room}
                    onChange={(value) => dispatch(setRoom(value))}
                    minAvailable={minAvailable}
                  />
                </div>
                <h2>Total Rp {totalCost ?? 0} ,-</h2>
                *(Tax Included)
                {/* <button
                  className="btn"
                  style={{ background: "#30552f", color: "white" }}
                  disabled={
                    night === 0 || roomType === null || isAnyQuotaUnavailable || totalCost === 0
                  }
                  onClick={() =>
                    navigate(`/accomodation/${slug}/booker-information`)
                  }
                >
                  Book Now
                </button> */}
                  {isLoggedIn ? (
                  <button
                    className="btn"
                    style={{ background: "#30552f", color: "white" }}
                    disabled={
                      night === 0 ||
                      roomType === null ||
                      isAnyQuotaUnavailable ||
                      totalCost === 0
                    }
                    onClick={() =>
                      navigate(`/accomodation/${slug}/booker-information`)
                    }
                  >
                    Book Now
                  </button>
                ) : (
                  <button
                    className="btn"
                    style={{ background: "#30552f", color: "white" }}
                    onClick={() => navigate("/login")}
                  >
                    Login to Book
                  </button>
                )} 

                {modal && (
                  <div className="modals1">
                    <div className="modal-content">
                      <a className="close" onClick={toggleModal}>
                        Tutup (X)
                      </a>
                      <h4>Tipe Kamar: {roomType}</h4>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <div
                          style={{
                            backgroundColor: "#fc036f", // Room Full color
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                          }}
                        ></div>
                        <span>Room Full</span>
                        <div
                          style={{
                            backgroundColor: "white", // Room Available color
                            width: "20px",
                            height: "20px",
                            borderRadius: "50%",
                            border: "1px solid #000", // Add border to make the white box visible
                          }}
                        ></div>
                        <span>Ruang Tersedia</span>
                      </div>

                      <table>
                        <thead>
                          <tr>
                            <th>Tanggal</th>
                            <th>Ruang Tersedia</th>
                          </tr>
                        </thead>
                        <tbody>
                          {quotaStay30?.data?.quota.map((quota, index) => (
                            <tr
                              key={index}
                              style={{
                                fontSize: "14px",
                                textAlign: "center",
                                backgroundColor:
                                  quota.available === 0 ? "#fc036f" : "white",
                              }}
                            >
                              <td>{format(quota.date, "dd-MMMM-yyyy")}</td>
                              <td style={{ textAlign: "center" }}>
                                {quota.available}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GlampingDetail;
