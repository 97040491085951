import React, { useContext, useState, useEffect } from "react";
import "./glampingSummary.css";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import AuthService from "../../services/authService";
import { handleBookGlamping } from "../../services/ticketService";
import format from "date-fns/format";
import { AppContext } from "../../App";
import { BeatLoader } from "react-spinners";

const GlampingSummary = () => {
  const { name, email, setName, setEmail, phone, setPhone, setBookingNumber } =
    useContext(AppContext);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const room = useSelector((state) => state.glamping.room);
  const nights = useSelector((state) => state.glamping.nights);
  const roomType = useSelector((state) => state.glamping.roomType);
  const start_date = useSelector((state) => state.glamping.startDate);
  const end_date = useSelector((state) => state.glamping.endDate);
  const totalCost = useSelector((state) => state.glamping.totalCost);
  const title = useSelector((state) => state.glamping.title);
  const { slug, bookingNumber } = useParams();
  const type = "stay";
  const [error, setError] = useState();

  const token = localStorage.getItem("user");

  const [isLoading, setIsLoading] = useState(false);

  const handleButtonClick = async () => {
    setIsLoading(true); // Start loading animation
    // Simulate an asynchronous operation like an API call
    // Replace this with your actual asynchronous operation
    try {
      await handleBookGlamping(
        navigate,
        name,
        email,
        phone,
        type,
        room,
        start_date,
        end_date,
        roomType,
        setBookingNumber,
        slug
      );
    } catch (error) {
      console.error("Booking failed:", error);
      setError(error.toString());
    }
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    setIsLoading(false); // Stop loading animation once operation is complete
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (totalCost === 0 || room === 0) {
          navigate(`/accomodation/${slug}`);
        }
        const response = await AuthService.getProfile();
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        setPhone(response.data.user.phone);
        // dispatch(setProfile(response.data.user));
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setError("Failed to fetch profile");
          window.location.href = "/login";
        }
      }
    };
    fetchProfile();
  }, [dispatch]);

  return (
    <div>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Booker Information</h1>
        <div className="bg-booking">
          <div className="bg-white">
            <h5>
              Summary: <br /> {title}
            </h5>
            <div className="summary-bg">
              <span>
                Jumlah Room:
                <b>
                  {" "}
                  {room} {roomType}{" "}
                </b>{" "}
              </span>
              <span>
                Check-In:
                <b> {start_date && format(start_date, "dd-MM-yyyy")} </b>
                <br />
                Check-Out: <b> {end_date && format(end_date, "dd-MM-yyyy")} </b>
              </span>
              <span>
                Total Price: <b> Rp {totalCost},- </b>{" "}
              </span>
            </div>
            <div className="form">
              <div className="form-group">
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Phone"
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <button
              className="btn btn-book"
              onClick={
                handleButtonClick
                // () =>
                // handleBookGlamping(
                //   navigate,
                //   name,
                //   email,
                //   phone,
                //   type,
                //   room,
                //   start_date,
                //   end_date,
                //   roomType,
                //   setBookingNumber,
                //   slug
                // )
              }
            >
              {isLoading ? <BeatLoader size={8} color="#FFF" /> : "Lanjutkan"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GlampingSummary;
