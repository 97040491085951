import React, { useContext, useState, useEffect } from "react";
import "./ticketDetailPayment.css";
import { useNavigate, useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import AuthService from "../../services/authService";
import { handleBookTicket } from "../../services/ticketService";
import { AppContext } from "../../App";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader"; // Import BeatLoader for loading animation

import format from "date-fns/format";

const TicketDetailPayment = ({ attraction }) => {
  const {
    name,
    setName,
    email,
    setEmail,
    phone,
    setPhone,
    setBookingNumber,
  } = useContext(AppContext);
  const navigate = useNavigate();
  const totalCost = useSelector((state) => state.ticket.totalCost);
  const jumlahTiket = useSelector((state) => state.ticket.jumlahTiket);
  const selectedDate = useSelector((state) => state.ticket.selectedDate);
  const token = localStorage.getItem("user");
  const [error, setError] = useState("");
  const { slug } = useParams();
  const date = selectedDate
    ? format(new Date(selectedDate), "yyyy/MM/dd")
    : null;
  const data = {
    qty: jumlahTiket,
    date: date,
    type: "ticket",
  };

  const [isLoading, setIsLoading] = useState(false);

  // console.log("dataaaa");
  // console.log(data);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        if (totalCost === 0) {
          navigate(`/ticket-attraction/${slug}`);
        }
        const response = await AuthService.getProfile();
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        setPhone(response.data.user.phone);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          // Redirect to login page
          localStorage.removeItem("user");
          navigate("/login");
        } else {
          setError("Failed to fetch profile");
        }
      }
    };
    fetchProfile();
  }, []);

  const handleButtonClick = async () => {
    setIsLoading(true);
    try {
      await handleBookTicket(
        { name, email, phone, ...data },
        token,
        slug,
        navigate,
        setBookingNumber
      );
      // Call handleBookGlamping here with necessary arguments
      // Example: await handleBookGlamping(name, email, phone, ...);
      // You might need to adjust the arguments based on your actual function signature and required data
    } catch (error) {
      setError("An error occurred"); // Handle errors appropriately
    } finally {
      setIsLoading(false); // Ensure loading is set to false in the end
    }
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="bg-ticket">
        <h1>Booker Information</h1>
        <div className="bg-booking">
          <div className="bg-white">
            <h5>Summary</h5>
            <div className="summary-bg">
              <span>Jumlah Tiket: {jumlahTiket}</span>
              <span>
                Tanggal Masuk:{" "}
                {selectedDate && format(selectedDate, "dd-MM-yyyy")}
              </span>
              <span>Harga Total: Rp {totalCost},-</span>
            </div>
            <div className="form">
              <div className="form-group">
                <input
                  placeholder="Name"
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Email"
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <input
                  placeholder="Phone"
                  type="tel"
                  id="mobile"
                  name="mobile"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>
            <button
              disabled={isLoading} // Disable button when loading
              className="btn btn-book"
              onClick={handleButtonClick}
            >
              {isLoading ? <BeatLoader size={8} color="#FFF" /> : "Lanjutkan"}
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TicketDetailPayment;
