import React, { useState, useEffect, memo } from "react";
import "./NavigationLanding.css";
// import { SiYourtraveldottv } from "react-icons/si";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import kalselLogo from "../../assets/kalselpark-logo-new-min.png";
import loksadoLogo from "../../assets/logo-loksado1.png";
import mountainviewLogo from "../../assets/logo-mountainview1.png";
import pasarapungLogo from "../../assets/logo-pasarapung1.png";
import bukitLogo from "../../assets/logo-bukit1.png";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/authService";
import Dropdown from "react-bootstrap/Dropdown";
import Information from "../../assets/icon-navbar/about.png";
import Event from "../../assets/icon-navbar/event.png";
import Maps from "../../assets/icon-navbar/maps.png";
import Package from "../../assets/icon-navbar/package.png";
import Resto from "../../assets/icon-navbar/resto.png";
import Shop from "../../assets/icon-navbar/shop.png";
import Stay from "../../assets/icon-navbar/stay.png";
import Ticket from "../../assets/icon-navbar/ticket-icon.png";
import Wahana from "../../assets/icon-navbar/wahana_white.png";
import { useSelector, useDispatch } from "react-redux";
import { setIsLoggedIn, setLogout } from "../../features/login/loginSlice";
import { Link } from "react-router-dom";

const NavigationLanding = () => {
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const dispatch = useDispatch();
  const [isShown, setIsShown] = useState(false);
  const [modalComingSoon, setModalComingSoon] = useState(false);

  const handleLogin = () => {
    dispatch(setIsLoggedIn(true));
  };

  // const handleLogout = () => {
  //   dispatch(setLogout());
  // };

  const [isMenuOpen, setMenuOpen] = useState(true);
  const navigate = useNavigate();
  const [active, setActive] = useState("navBar");
  const [transparent, setTransparent] = useState("header");

  // const toggleMenu = () => {
  //   setMenuOpen(!isMenuOpen);
  // };

  const token = localStorage.getItem("user");
  useEffect(() => {
    if (token) {
      handleLogin(true);
    }
  }, []);

  const handleShown = () => {
    setIsShown(!isShown);
  };

  const showNav = () => {
    setActive("navBar activeNavbar");
  };

  // const addBg = () => {
  //   if (window.scrollY >= 10) {
  //     setTransparent("header activeHeader");
  //   } else {
  //     setTransparent("header");
  //   }
  // };
  // window.addEventListener("scroll", addBg);

  const removeNavbar = () => {
    setActive("navBar");
  };

  return (
    <section className="navBarSectionLanding">
      <div className={transparent}>
        <div className="logoDiv">
          <a className="logo">
            <h1 className="flex">
              <div className="col-md-7">
                <img
                  src={kalselLogo}
                  alt=""
                  className="iconLogo"
                  onClick={() => navigate("/")}
                />
              </div>
            </h1>
          </a>
          {/* <div className="subLogo-landing"> */}
          <div className="icon-menu-landing" onClick={() => navigate("/package")}>
            <img className="img-icon" src={Package} alt="" />
            <span className="icon-title">Package</span>
          </div>

          <div className="icon-menu-landing">
          <Link to="/events">
            <img className="img-icon" src={Event} alt="" />
            <span className="icon-title">Event</span>
            </Link>
          </div>

          <div
            className="icon-menu-landing"
            onClick={() => navigate("/ticket-attraction")}
          >
            <img className="img-icon" src={Stay} alt="" />
            <span className="icon-title">Resort</span>
          </div>

          <div
            className="icon-menu-landing"
            onClick={() => navigate("/merchandise")}
          >
            <img className="img-icon" src={Shop} alt="" />
            <span className="icon-title">Shops</span>
          </div>

          <div
            className="icon-menu-landing"
            onClick={() => navigate("/ticket-attraction")}
          >
            <img className="img-icon" src={Ticket} alt="" />
            <span className="icon-title">Tickets</span>
          </div>

          <div
            className="icon-menu-landing"
            onClick={() => navigate("/restaurants")}
          >
            <img className="img-icon" src={Resto} alt="" />
            <span className="icon-title">Restaurant</span>
          </div>

          <div
            className="icon-menu-landing"
            onClick={() => navigate("/ticket-attraction")}
          >
            <img className="img-icon" src={Wahana} alt="" />
            <span className="icon-title">Attractions</span>
          </div>

          <div className="icon-menu-landing" onClick={() => navigate("/maps")}>
            <img className="img-icon" src={Maps} alt="" />
            <span className="icon-title">Maps</span>
          </div>

          <div className="icon-menu-landing">
            <img className="img-icon" src={Information} alt="" />
            <span className="icon-title">About</span>
          </div>
          {/* </div> */}
          {/* <div className="subLogo">
            <Link to="/kalselpark-mountainview">
              <div>
                <h1 className="flex">
                  <img src={mountainviewLogo} alt="" className="iconSubLogo" />
                </h1>
              </div>
            </Link>

            <div>
              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">
                  <img src={pasarapungLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>

            <div>
              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">
                  <img src={loksadoLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>

            <div>
              <a className="logo" onClick={() => navigate("/coming-soon")}>
                <h1 className="flex">
                  <img src={bukitLogo} alt="" className="iconSubLogo" />
                </h1>
              </a>
            </div>
          </div> */}
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem-mobile">
              <Dropdown>
                <Dropdown.Toggle
                  // onClick={handleShown}
                  variant="success"
                  id="dropdown-basic"
                >
                  More
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => navigate("/events")}>
                    Events
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Hotel</Dropdown.Item>
                  <Dropdown.Item onClick={() => navigate("/merchandise")}>
                    Shops
                  </Dropdown.Item>

                  <Dropdown.Item href="restaurants">Restaurant</Dropdown.Item>

                  <Dropdown.Item href="#/action-3">Attraction</Dropdown.Item>
                  <Dropdown.Item href="maps">Maps</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>

            <li className="navItem logoHide">
              <Link className="navLink" to="/kalselpark-mountainview">
                <img src={mountainviewLogo} alt="" className="iconSubLogo" />
              </Link>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <a className="navLink" href="/coming-soon">
                <img src={pasarapungLogo} alt="" className="iconSubLogo" />
              </a>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <a className="navLink" href="/coming-soon">
                <img src={loksadoLogo} alt="" className="iconSubLogo" />
              </a>
            </li>

            <li className="navItem logoHide">
              {/* <a className="navLink" onClick={handleModal}> */}
              <a className="navLink" href="/coming-soon">
                <img src={bukitLogo} alt="" className="iconSubLogo" />
              </a>
            </li>

            {/* -------------IS LOGGED IN--------------------- */}
            <div className="headerBtns flex">
              {isLoggedIn && token ? (
                <>
                  <button onClick={() => navigate("/profile")} className="btn">
                    <a>Profile</a>
                  </button>

                  <button onClick={AuthService.logout} className="btn">
                    <a>Logout</a>
                  </button>
                </>
              ) : (
                <>
                  {" "}
                  <button
                    onClick={() => navigate("/login")}
                    className="btn loginBtn"
                  >
                    <a>Login</a>
                  </button>
                  <button onClick={() => navigate("/register")} className="btn">
                    <a>Register</a>
                  </button>
                </>
              )}
            </div>
            {/* -------------IS LOGGED IN--------------------- */}
          </ul>

          <div onClick={removeNavbar} className="closeNavbar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>
      </div>
      {/* {isShown ? ( */}
      {/* <div className="is-shown-landing"></div> */}
      {/* ) : null} */}
    </section>
  );
};

export default memo(NavigationLanding);
