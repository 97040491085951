import React from "react";
import Navigation from "../../components/Navbar/Navigation";
import "./gunungPage.css";
import gunung from "../../assets/bg-mountain-view.png";
import Banner from "../../components/Banner/Banner";
import img1 from "../../assets/bukit-langgara.jpg";
import img2 from "../../assets/kahung.jpg";
import img3 from "../../assets/martapura.jpeg";
import img4 from "../../assets/mandiangin-1.jpg";
import ImgGallery from "../../components/ImgGallery/ImgGallery";
import imgLogo from "../../assets/kp-mountain-logo.png";
import Footer from "../../components/Footer/Footer";
import { useNavigate } from "react-router-dom";
const Data = [
  {
    id: 1,
    imgSrc: img1,
    descTitle: "Bukit Langgara",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/forest-page",
  },
  {
    id: 2,
    imgSrc: img2,
    descTitle: "Kahung",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 3,
    imgSrc: img3,
    descTitle: "Martapura",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 4,
    imgSrc: img4,
    descTitle: "Mandiangin",
    location: "South Kalimantan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
];
const images = Data.map(item => ({
  original: item.imgSrc,
  thumbnail: item.imgSrc,
}));

const GunungPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <Banner imgLogo={imgLogo} img={gunung} />

      <div className="bg">
        <div className="container">
          <div className="mainContentGunung grid"></div>

          <div className="articleMountain">
            <h4>Mountains Outbound</h4>
            <div className="inline">
              <ImgGallery items={images}/>
              <p>
                Mountain Outbound adalah tempat rekreasi alam yang terletak di
                Tahura Sultan Adam Mandiangin, sebuah kawasan hutan lindung yang
                indah di Kalimantan Barat, Indonesia. Tempat ini menawarkan
                pengalaman yang mendebarkan bagi para pengunjung dengan berbagai
                kegiatan seperti hiking, flying fox, climbing, dan berbagai
                permainan luar ruangan lainnya yang menantang. Selain menyajikan
                pemandangan alam yang menakjubkan, Mountain Outbound juga
                menjadi tempat ideal bagi pengunjung yang ingin merasakan
                sensasi petualangan dan kegiatan outdoor yang memacu adrenalin.
                Dengan fasilitas yang memadai dan dukungan tim yang
                berpengalaman, tempat ini menjadi destinasi populer bagi para
                pecinta alam dan petualangan di Kalimantan Barat.
                <h5>Jam Operasional</h5>
                
                <p className="operasional">Buka Setiap Hari (07:00 - 15:00)</p>
                <button onClick={() => navigate("/kalselpark-mountainview/glamping")} className="btn">Book</button>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default GunungPage;
