import React, { useEffect } from "react";
import "./about.css";

import video from "../../assets/video-kalsel.mp4";
import Aos from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
      <section className="about section">
        <div className="secContainer">
          <h2 className="title">About Kalsel Park</h2>
          <div className="mainContent container">
            <p>
              Selamat datang di Kalselpark, perpaduan harmonis antara keindahan
              alam dan warisan budaya yang memukau, terletak di Kalimantan
              Selatan, Indonesia. Kalselpark bukan hanya sekadar destinasi
              wisata alam biasa, melainkan sebuah perjalanan yang memperkaya
              jiwa dan menyentuh hati setiap pengunjung. Kalselpark membanggakan
              dirinya sebagai surga bagi para pencinta alam dengan ragam
              keindahan yang memukau. Di sini, Anda akan menemukan
              gunung-gemawan menjulang tinggi memancarkan kemegahan alam
              Kalimantan Selatan. Sungai-sungai yang mengalir dengan lembut
              mengiringi perjalanan, menawarkan kesempatan untuk merasakan
              ketenangan dan keindahan alam yang memesona.
            </p>
            {/* <div
            data-aos="fade-up"
            data-aos-duration="2000"
            className="singleItem"
            >
            <img src={img1} alt="image name" />
            <h3>lorem ipsun dolor sit amet</h3>
            <p>
            It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
              </p>
              </div>
              
              <div
              data-aos="fade-up"
              data-aos-duration="2500"
              className="singleItem"
          >
            <img src={img2} alt="image name" />
            <h3>lorem ipsun dolor sit amet</h3>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout. The
              point of using Lorem Ipsum is that it has a more-or-less normal
              distribution of letters, as opposed to using 'Content here,
              content here', making it look like readable English.
            </p>
          </div>
          
          <div
            data-aos="fade-up"
            data-aos-duration="3000"
            className="singleItem"
            >
            <img src={img2} alt="image name" />
            <h3>lorem ipsun dolor sit amet</h3>
            <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The
            point of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters, as opposed to using 'Content here,
            content here', making it look like readable English.
            </p>
          </div> */}
          </div>

          <div className="videoCard container">
            <div className="cardContent grid">
              <div
                data-aos="fade-right"
                data-aos-duration="3000"
                className="cardText"
              >
                <h2>Experience The Beauty of Kalsel</h2>
                <p>
                  Lorem ipsum dolor sit amet ipsum dolor sit amet ipsum dolor
                  sit amet.
                </p>
              </div>

              <div
                data-aos="fade-left"
                data-aos-duration="3000"
                className="cardVideo"
              >
                <video src={video} loop muted type="video/mp4"></video>
              </div>
            </div>
          </div>
        </div>
      </section>
  );
};

export default About;
