import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { merchDetail } from '../../services/merchandiseService';
import "./merchandiseDetail.scss";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";

function MerchandiseDetail() {
    const { slug } = useParams();
    const [merch, setMerch] = useState(null);
    
    useEffect(() => {
        try {
            const fetchData = async () => {
                const response = await merchDetail(slug);
                setMerch(response.data.show);
            };
            fetchData();
        } catch (error) {
            console.error("Failed to fetch news detail:", error);
        }
    }, [slug]);
    console.log('ini slug');
    console.log(slug);
    console.log('ini detail merchandise');
    console.log(merch);
  return (
    <>
        <Navigation />
            <div className="header"></div>
            <section className='space--lg'>
                <div className='containers'>
                    <div className='row justify-content-around'>
                        <div className='col-md-7 col-lg-6'>
                            <div className='boxed--border'>
                              <img className="border--round" src={merch && merch.image} alt="" />
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-4'>
                          <h2>{merch && merch.title}</h2>
                          <h3>Rp 100.000,-</h3>
                          <p>{merch && merch.description.replace(/(<([^>]+)>)/gi, "")}</p>
                          <button
                              className="btn"
                              style={{ background: "#30552f", color: "white" }}
                          >
                              Pesan Sekarang
                          </button>
                        </div>
                    </div>
                </div>
            </section>
        <Footer />
    </>
  )
}

export default MerchandiseDetail