import React from 'react'
import "./pills.scss";

const Pills = ({ image, title, location }) => {
    return (
        <div className="pills-card">
            <div className="image-container">
                <img src={image} alt={title} />
            </div>
            <div className="pills-content">
                <h3>{title}</h3>
                <p>{location}</p>
            </div>
        </div>
    );
};

export default Pills