import React from "react";
import contact from "../../assets/contact-us.png";
import { useNavigate } from "react-router-dom";
import "./contactComponent.css";

const ContactComponent = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="contact">
        <img src={contact} alt="" onClick={() => navigate("/")} />
      </div>
    </>
  );
};

export default ContactComponent;
