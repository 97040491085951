import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
// console.log('API_URL:', process.env.REACT_APP_API_URL);

//is authenticated
const isAuthenticated = () => {
  return localStorage.getItem("user") !== null;
};

//Consume API Register
const register = async (
  name,
  email,
  phone,
  password,
  password_confirmation
) => {
  try {
    const response = await axios.post(API_URL + "register", {
      name,
      email,
      phone,
      password,
      password_confirmation,
    });
    return response.data;
  } catch (error) {
    console.error("Error during registration:", error);
    throw error;
  }
};

// return axios.post(API_URL + "register", {
//   name,
//   email,
//   phone,
//   password,
//   password_confirmation,
// });
// };

//Consume API Login
const login = (email, password) => {
  return axios
    .post(API_URL + "login", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("user", response.data.token);
      }
      //   console.log(response.data.user.is_verified);
      return response.data;
    });
};

//profile
const getProfile = () => {
  const token = localStorage.getItem("user");
  //   console.log(token.token);
  return axios.get(`${API_URL}profile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// Handle Logout
const logout = () => {
  const token = localStorage.getItem("user");
  //   console.log("lgogouttt");
  if (token) {
    return axios
      .post(
        `${API_URL}logout`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((error) => {
        console.error("Logout failed:", error);
      })
      .finally(() => {
        // localStorage.removeItem("token");
        localStorage.removeItem("user");
        // Navigate to login page after logout
        window.location.href = "/";
      });
    // .then(() => {
    //   // localStorage.removeItem("token");
    //   localStorage.removeItem("user");
    //   // Navigate to login page after logout
    //   window.location.href = "/";
    // })
    // .catch((error) => {
    //   console.error("Logout failed:", error);
    // });
  }
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const authService = {
  register,
  login,
  logout,
  getCurrentUser,
  getProfile,
  isAuthenticated,
};

export default authService;
