import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import format from "date-fns/format";
import { useDispatch, useSelector } from "react-redux";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import "./date.css";

import { setSelectedDate } from "../../features/ticket/ticketSlice";

const DatePicker = ({ onDateChange }) => {
  const dispatch = useDispatch();
  const { selectedDate } = useSelector((state) => state.ticket);
  // const [selectedDate, setSelectedDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);
  // console.log("selected date");
  // console.log(selectedDate);
  // console.log("selected date");

  const handleDateChange = (date) => {
    const adjustedDate = new Date(
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    );
    dispatch(setSelectedDate(adjustedDate.toISOString()));
    setIsOpen(false); // close the calendar after a date is selected
    onDateChange(adjustedDate);
  };

  const toggleCalendar = (e) => {
    e.stopPropagation(); // Prevent click from closing the calendar
    setIsOpen(!isOpen);
  };
  // console.log(selectedDate);
  return (
    <div className="calendarWrap">
      <input
        style={{ textAlign: "center" }}
        type="text"
        value={format(selectedDate, "dd/MM/yyyy")}
        onClick={toggleCalendar}
        readOnly
      />
      <button className="btn btn-calendar" onClick={toggleCalendar}>
        Pilih Tanggal
      </button>
      {isOpen && (
        <Calendar
          date={new Date(selectedDate)}
          onChange={handleDateChange}
          minDate={new Date()}
          maxDate={addDays(new Date(), 30)}
        />
      )}
    </div>
  );
};

export default DatePicker;
