import { createSlice } from "@reduxjs/toolkit";

export const eventSlice = createSlice({
  name: "event",
  initialState: {
    totalCostEvent: 0,
    selectedClassEvent: null,
  },
  reducers: {
    setTotalCostEvent: (state, action) => {
      state.totalCostEvent = action.payload;
    },
    setSelectedClassEvent: (state, action) => {
      state.selectedClassEvent = action.payload;
    },
    resetEventState: (state) => {
        state.selectedClassEvent = null;
    }
  },
});

export const { setTotalCostEvent, setSelectedClassEvent, resetEventState } = eventSlice.actions;

export default eventSlice.reducer;
