import React from "react";
import "./banner.css";
import { useNavigate } from "react-router-dom";
// import sungai from "../../assets/sungai-page.jpeg";
// import Aos from "aos";
// import "aos/dist/aos.css";

const Banner = (props) => {
  const navigate = useNavigate();

  return (
    <section className="banner"
      style={{
        // top: "2rem",
        height: "80vh",
        // background: `linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)),
        // url(${props.img})`,
        background: `url(${props.img})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        position: "relative",
        backgroundSize: "cover",
      }}

      // style={{ width: 128, height: 193, backgroundImage: `url(${imageUrl})` }}
      //  className="banner"
    >

      <div className="secContainer container">
        <div className="homeText">
          {/* <img src={props.imgLogo} alt="img" /> */}
        </div>
      </div>


      <div>

      </div>
    </section>
  );
};

export default Banner;
