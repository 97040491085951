import React from "react";
import Home from "../../components/Home/Home";
import Popular from "../../components/Popular/Popular";
// import Offer from "../../components/Offer/Offer";
import About from "../../components/About/About";
import Blog from "../../components/Blog/Blog";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navbar/Navigation";
import ExplorePage from "../../components/ExplorePage/ExplorePage";
import NavigationLanding from "../../components/NavbarLanding/NavigationLanding";

const Landing = () => {
  return (
    <>
      {/* <Navigation /> */}
    
      <Home />
      <ExplorePage />
      <Popular />
      <About />
      <Blog />
      <Footer />
    </>
  );
};

export default Landing;
