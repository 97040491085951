import React, { useState, useEffect } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format, differenceInCalendarDays } from "date-fns";
import "./dateRange.css";
import {
  setStartDate,
  setEndDate,
  setNights,
} from "../../features/glamping/glampingSlice";
import { useDispatch, useSelector } from "react-redux";

const DateRange = ({ quotaStay }) => {
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.glamping);
  const [open, setOpen] = useState(false);

  // new
  // useEffect(() => {
  //   if (!Array.isArray(quotaStay)) {
  //     console.error("quotaStay is not an array", quotaStay);
  //   }
  // }, [quotaStay]);

  const handleChange = (ranges) => {
    const { startDate, endDate } = ranges.selection;
    // Check if startDate and endDate are the same

    const formattedStartDate = format(ranges.selection.startDate, "yyyy-MM-dd");
    const formattedEndDate = format(ranges.selection.endDate, "yyyy-MM-dd");
    dispatch(setStartDate(formattedStartDate));
    dispatch(setEndDate(formattedEndDate));
  };

  const nightCount = differenceInCalendarDays(
    new Date(endDate),
    new Date(startDate)
  );

  dispatch(setNights(nightCount));

  const handleClick = () => setOpen((prev) => !prev);

  // new
  // const getDayStyle = (date) => {
  //   const formattedDate = format(date, "yyyy-MM-dd");
  //   const quota = Array.isArray(quotaStay) ? quotaStay.find((q) => q.date === formattedDate) : null;
  //   if (quota && quota.available === 0) {
  //     return { backgroundColor: "red", color: "white", borderRadius: "50%" };
  //   }
  //   return {};
  // };

  return (
    <div className="container">
      <span className="rangeDateDisplay" onClick={handleClick}>{`${format(
        startDate,
        "dd/MMM/yyyy"
      )} - ${format(endDate, "dd/MMM/yyyy")}`}</span>
      <div style={{ textAlign: "center", fontSize: "1.5rem", margin: "10px" }}>
        {nightCount} nights
      </div>
      <button onClick={handleClick} className="btn">
        Pilih Tanggal
      </button>
      {open && (
        <DateRangePicker
          // ranges={[date]}
          ranges={[
            {
              startDate: new Date(startDate), // Convert string back to Date object
              endDate: new Date(endDate), // Convert string back to Date object
              key: "selection",
            },
          ]}
          onChange={handleChange}
          minDate={new Date()}
          // dayContentRenderer={(date) => {
          //   // const dayStyle = getDayStyle(date);
          //   return <div >{format(date, "d")} test</div>;
          // }}
        />
      )}
    </div>
  );
};

export default DateRange;
