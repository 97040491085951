import React, { useState, useEffect } from "react";
import { newsDetail } from "../../services/newsService";
import { useParams } from "react-router-dom";
import Navigation from "../../components/Navbar/Navigation";
import Footer from "../../components/Footer/Footer";
import "./blogDetail.css";

const BlogDetail = () => {
  const { slug } = useParams();
  const [news, setNews] = useState(null);
  // console.log(slug);
  useEffect(() => {
    try {
      const fetchData = async () => {
        const response = await newsDetail(slug);
        setNews(response.data);
      };
      fetchData();
    } catch (error) {
      console.error("Failed to fetch news detail:", error);
    }
  }, [slug]);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long", // "Monday"
      year: "numeric", // "2024"
      month: "long", // "July"
      day: "2-digit", // "15"
    };
    return new Date(dateString).toLocaleDateString("id-ID", options);
  };

  return (
    <>
      <Navigation />
      <div className="header"></div>
      <div className="blogDetailNews">
        <div className="newsHeader">
          <h1>{news && news.show.title}</h1>
          {/* <p>{news && news.news.created_at}</p> */}
          <p>{news && formatDate(news.news.created_at)}</p>
        </div>
        <img className="image-news" src={news && news.show.image} alt="" />

        <div className="newsContent">
          {/* <p>{news && news.show.description.replace(/(<([^>]+)>)/gi, "")}</p> */}
          {/* {news && news.show.description} */}
          {news && (
            <div dangerouslySetInnerHTML={{ __html: news.show.description }} />
          )}
        </div>

        <p>{newsDetail && newsDetail.content}</p>
      </div>
      <Footer />
    </>
  );
};

export default BlogDetail;
