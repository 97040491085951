import React, { useRef, useEffect } from "react";
import "./roomCounter.css";

const RoomCounter = ({ value, onChange, minAvailable }) => {
  const incrementButtonRef = useRef(null);

  useEffect(() => {
    incrementButtonRef.current.focus();
  });
  const incrementRoom = () => {
    if (value < minAvailable) {
      onChange(value + 1);
    }
  };

  const decrementRoom = () => {
    if (value > 1) {
      onChange(value - 1);
    }
  };

  return (
    <div className="personCounter container">
      <button
        className="btnDecrement"
        onClick={decrementRoom}
        disabled={value === 1}
      >
        -
      </button>
      <span className="person">{value}</span>
      <button
        className="btnIncrement"
        onClick={incrementRoom}
        disabled={value >= minAvailable}
        ref={incrementButtonRef}
      >
        +
      </button>
    </div>
  );
};

export default RoomCounter;
