import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./features/login/loginSlice";
import profileReducer from "./features/profile";
import glampingReducer from "./features/glamping/glampingSlice";
import ticketReducer from "./features/ticket/ticketSlice";
import eventReducer from "./features/event/eventSlice";
import packageReduce from "./features/package/packageSlice";

export const store = configureStore({
  reducer: {
    login: loginReducer,
    profile: profileReducer,
    glamping: glampingReducer,
    ticket: ticketReducer,
    event: eventReducer,
    package: packageReduce,
  },
});
