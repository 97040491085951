import React, { useState, useEffect } from 'react'
import "./package.css"
import Navigation from '../../components/Navbar/Navigation'
import Footer from '../../components/Footer/Footer'
import { fetchPackage } from '../../services/ticketService'
import { useDispatch } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { BsArrowRightShort } from 'react-icons/bs'
import Carousel from "react-multi-carousel";
import { BeatLoader } from 'react-spinners'


const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6,
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    },
};

function Package() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [packages, setPackages] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const data_package = await fetchPackage();
            console.log(data_package);
            setPackages(data_package.data.packageHoliday)
        }
        fetchData();
    }, []);

  return (
      <section className="bg--secondary">
        <Navigation />
        <div className="header"></div>
        <div className='package section container'>
            <div className="secContainer">
                <div className="secIntro">
                    <div className="secTitle">Packages</div>
                </div>
            </div>
            <div className="secIntroSub">
                <div className="secTitleSub">Main Package</div>
            </div>

            <Carousel responsive={responsive}>
                  {packages.map(
                    ({
                          id_package_holiday,
                          title,
                          slug,
                          type,
                          img_path,
                          price,
                          category,
                    }) => {
                        return (
                            <div key={id_package_holiday} className="packageCard">
                                <div className='att-image'>
                                    <img src={img_path} alt={title} />
                                    <div className='overlayInfo'>
                                        <h3>{title}</h3>
                                        <p>Rp {price}</p>
                                        <Link to={`/package-detail/${slug}`}>
                                            <BsArrowRightShort className='icon'/>
                                        </Link>
                                    </div>
                                </div>
                                <div className='destFooter'>
                                    <div className='destText flex'>
                                        <Link to={`/package-detail/${slug}`}>
                                            <h6>{title}</h6>
                                        </Link>
                                        <p>Rp {price}</p>
                                    </div>  
                                </div>
                            </div>
                        )
                    }
                  ) ?? <BeatLoader/>}
            </Carousel>

        </div>
        <Footer />
    </section>
  )
}

export default Package