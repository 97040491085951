import React, { useEffect, useState } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
// import imageBg from "../../assets/bg-image-landing.png";
import bannerService from "../../services/bannerService"; // Adjust the import path as needed
import tiket from "../../assets/tiket.png";
import contact from "../../assets/contact-us.png";
import NavigationLanding from "../NavbarLanding/NavigationLanding";

import loksadoLogo from "../../assets/logo-loksado1.png";
import mountainviewLogo from "../../assets/logo-mountainview1.png";
import pasarapungLogo from "../../assets/logo-pasarapung1.png";
import bukitLogo from "../../assets/logo-bukit1.png";

import ContactComponent from "../../components/ContactComponent/ContactComponent";
import TiketComponent from "../../components/TiketComponent/TiketComponent";
import { Link } from "react-router-dom";

const Home = () => {
  const [banners, setBanners] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    // Fetch banners when the component mounts
    bannerService
      .fetchBanners()
      .then((response) => {
        setBanners(response.data.data.banners);
        // console.log(response.data.data); // Assuming the response has a 'data' field with the banners
      })
      .catch((error) => {
        console.error("Error fetching banners:", error);
      });
  }, []);

  return (
    <>
      <NavigationLanding />
      <section className="home">
        <div className="secContainer">
          {/* <div className="homeText"> */}
          <div className="sidebar">
            <div className="logo-sub">  
              <div className="logo-site">
                <Link to="/kalselpark-mountainview">
                  <img className="logo-site" src={mountainviewLogo} alt="" />
                </Link>
              </div>
              <div className="logo-site">
                <Link to="/coming-soon">
                  <img className="logo-site" src={loksadoLogo} alt="" />
                </Link>
              </div>
              <div className="logo-site">
                <Link to="/coming-soon">
                  <img className="logo-site" src={bukitLogo} alt="" />
                </Link>
              </div>
              <div className="logo-site">
                <Link to="/coming-soon">
                  <img className="logo-site" src={pasarapungLogo} alt="" />
                </Link>
              </div>
            </div>

            <div className="desktop-banner">
              <Carousel>
                {banners.map((banner, index) => (
                  <Carousel.Item key={index}>
                    <a href={banner.link}>
                      <img
                        className="bg-overlay"
                        src={banner.images}
                        alt={banner.alt}
                      />
                    </a>
                  </Carousel.Item>
                ))}
              </Carousel>
            </div>
          </div>

          <div className="mobile-banner">
            <Carousel>
              {banners.map((banner, index) => (
                <Carousel.Item key={index}>
                  <img
                    className="bg-overlay"
                    src={banner.images_mobile}
                    alt={banner.alt}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
          </div>

          {/* <div className="ticket">
            <img
              src={tiket}
              alt=""
              onClick={() => navigate("/ticket-attraction")}
            />
          </div> */}
          <TiketComponent />

          <ContactComponent />

          {/* 
          <div className="contact">
            <img src={contact} alt="" />
          </div> */}
          {/* </div> */}
        </div>
      </section>
    </>
  );
};

export default Home;
