import React from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import qrcode from '../../assets/qr-code.png'
import "./detailActivity.css";
const DetailActivity = () => {
  const navigate = useNavigate();
  return (
    <section className="detailActivity section container">
      <div className="detailActivityMaxWidth">
        <h3 onClick={() => navigate("/profile")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Activity</h1>
        <h4>Entry Ticket</h4>
        <div className="detailActivityContainer">
          <h6>No. Ticket 001</h6>
            <img src={qrcode}alt="" />
        </div>
        <div className="detailActivityContainer">
          <h6>No. Ticket 002</h6>
          <img src={qrcode}alt="" />
        </div>
        <div className="detailActivityContainer">
          <h6>No. Ticket 003</h6>
          <img src={qrcode}alt="" />
        </div>
      </div>
    </section>
  );
};

export default DetailActivity;
