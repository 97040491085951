import { createSlice } from "@reduxjs/toolkit";

export const glampingSlice = createSlice({
  name: "glamping",
  initialState: {
    nights: 0,
    room: 1,
    roomType: null,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    totalCost: 0,
  },
  reducers: {
    setNights: (state, action) => {
      state.nights = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setRoomType: (state, action) => {
      state.roomType = action.payload;
    },
    setStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    setTotalCost: (state, action) => {
      state.totalCost = action.payload;
    },
    resetGlampingState: (state) => {
      state.nights = 0;
      state.room = 1;
      state.roomType = null;
      state.startDate = new Date().toISOString();
      state.endDate = new Date().toISOString();
      state.totalCost = 0;
    }
  },
});

export const {
  setNights,
  setRoomType,
  setStartDate,
  setEndDate,
  setTotalCost,
  setRoom,
  resetGlampingState,
} = glampingSlice.actions;
export default glampingSlice.reducer;
