import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export const fetchNews = async (limit, page) => {
    try {
      const response = await axios.get(
        `${API_URL}getNews?limit=${limit}&page=${page}`
      );
      return response.data;
    }catch(error){
        console.error("Failed to fetch news:", error);
        return null;
    }
}

export const newsDetail = async (slug) => {
    try {
      const response = await axios.get(`${API_URL}getNewsDetail/${slug}`);
      return response.data;
    }catch(error){
        console.error("Failed to fetch news detail:", error);
        return null;
    }
}