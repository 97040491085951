import React, { useContext, useState ,useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './packageSummary.css';
import { AppContext } from '../../App';
import { useSelector, useDispatch } from 'react-redux';
import Navigation from '../../components/Navbar/Navigation';
import AuthService from '../../services/authService';
import LayoutSummary from '../LayoutSummary/LayoutSummary';
import Footer from '../../components/Footer/Footer';
import { BeatLoader } from 'react-spinners';
import { handleBookPackage } from '../../services/ticketService';

const PackageSummary = () => {
    const { name, email , setName, setEmail, phone, setPhone, setBookingNumber } = useContext(AppContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const title = useSelector((state) => state.package.title);
    const jumlahPackage = useSelector((state) => state.package.jumlahPackage);
    const totalCostPackage = useSelector((state) => state.package.totalCostPackage);
    const { slug, bookingNumber } = useParams();
    const [error, setError] = useState();
    const token = localStorage.getItem("user");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchProfile = async () => {
            try {
                    if (totalCostPackage === 0 || jumlahPackage === 0) {
                        navigate(`/package/${slug}`);
                    }
                    const response = await AuthService.getProfile();
                    setName(response.data.user.name);
                    setEmail(response.data.user.email);
                    setPhone(response.data.user.phone);
                    // dispatch(setProfile(response.data.user));
            } catch (error) {
                if (error.response && error.response.status === 401) {
                    localStorage.removeItem("user");
                    navigate("/login");
                } else {
                    setError("Failed to fetch profile");
                    window.location.href = "/login";
                }
            }
        };
        fetchProfile();
    }, [dispatch]);

    const data = {
        qty : jumlahPackage,
        type: "package",
    }

    const handleButtonClick = async () => {
        setIsLoading(true);
        try {
            await handleBookPackage(
                {name, email, phone, ...data},
                token,
                slug,
                navigate,
                setBookingNumber
            )
        } catch (error) {
            setError("Booking failed: An error occurred", error); // Handle errors appropriately
        } finally {
            setIsLoading(false); // Ensure loading is set to false in the end
        }
    };
  return (
    <>
    <LayoutSummary>
        <div className="headers"></div>
        <h1>Booker Information</h1>
        <div className="bg-booking">
            <div className="bg-white">
                <h5>Summary: {title}</h5>
                <div className="summary-bg">
                    <span>
                        Jumlah: <b>{jumlahPackage}</b>
                    </span>
                    <span>
                        Total Harga: <b>{totalCostPackage}</b>
                    </span>
                </div>
                <div className="form">
                    <div className="form-group">
                        <input
                            placeholder="Name"
                            type="text"
                            id="name"
                            name='name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            placeholder="Email"
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            placeholder="Phone"
                            type="tel"
                            id="mobile"
                            name="mobile"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                </div>
                <button
                    className="btn btn-book"
                    onClick={
                        handleButtonClick
                    }
                >
                    {isLoading ? <BeatLoader size={8} color="#FFF" /> : "Lanjutkan"}
                </button>
            </div>
        </div>
    </LayoutSummary>
    </>
    
  )
}

export default PackageSummary